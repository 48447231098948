import React, { useState } from "react";
import { firestore } from "../data/firebase";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import Modal from "react-modal";
import { toast } from "react-toastify";

const PhoneModal = ({ isOpen, onClose, user }) => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  const handleSavePhone = async () => {
    if (!phone.trim()) {
      setError("Numărul de telefon este obligatoriu.");
      return;
    }

    try {
      // Verificăm dacă numărul de telefon există deja
      const usersRef = collection(firestore, "users");
      const phoneQuery = query(usersRef, where("phoneNumber", "==", phone));
      const querySnapshot = await getDocs(phoneQuery);

      if (!querySnapshot.empty) {
        // Dacă numărul de telefon există deja, afișăm un mesaj de eroare
        setError(
          "Numărul de telefon este deja utilizat de un alt cont. Dacă acest număr vă aparține, vă rugăm să ne contactați pentru asistență."
        );
        return;
      }

      // Salvăm numărul de telefon dacă este unic
      const userRef = doc(firestore, "users", user.uid);
      await updateDoc(userRef, { phoneNumber: phone });
      toast.success("Numărul de telefon a fost salvat cu succes!");
      setError("");
      onClose(); // Închide modalul după salvare
    } catch (error) {
      console.error("Eroare la salvarea numărului de telefon:", error);
      setError("Eroare la salvarea numărului de telefon. Încercați din nou.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        if (!phone.trim()) {
          setError(
            "Trebuie să introduceți un număr de telefon pentru a continua."
          );
          return;
        }
        onClose();
      }}
      className="phone-modal">
      <h2>Introduceți numărul de telefon</h2>
      <input
        type="tel"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder="Număr de telefon"
        required
      />
      {error && <p className="error-message">{error}</p>}
      <button onClick={handleSavePhone}>Salvează</button>
    </Modal>
  );
};

export default PhoneModal;
