import React, { useState, useEffect } from "react";
import "../styling/promotedSection.css";
import categoriesData from "../data/categories.json";
import { toast } from "react-toastify";
import { t } from "i18next";
import EditModal from "../components/EditModal";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import { collection, where, query, getDocs } from "firebase/firestore";
import { firestore } from "../data/firebase";

const PromotedSection = ({
  promotedServices,
  currentUserId,
  favorites = [], // Default to an empty array if undefined
  handleFavoriteClick,
}) => {
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [services, setServices] = useState([]);
  // eslint-disable-next-line
  const [userAds, setUserAds] = useState([]);
  const [selectedService, setSelectedService] = useState(null); // Adaugă starea
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // Adaugă starea
  const navigate = useNavigate();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Manage modal state
  const [editingService, setEditingService] = useState(null); // Service being edited

  // const stripeLink = "https://buy.stripe.com/test_7sI5mT3pN7MR72E4gh"; // Replace with your actual Stripe link

  const handleCardClick = (service) => {
    if (!currentUserId) {
      toast.error(t("promoted.not_authenticated"));
      return;
    }
    setSelectedService(service); // Setează serviciul selectat
    setIsDetailsModalOpen(true); // Deschide modalul
  };

  const getCategoryImage = (category) => {
    const categoryData = categoriesData.categories.find(
      (cat) => cat.name === category
    );
    return categoryData ? categoryData.image : null;
  };

  const openEditModal = (service) => {
    setEditingService(service);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditingService(null);
    setIsEditModalOpen(false);
  };

  // Handle opening the promote modal
  const handlePromoteClick = () => {
    if (!currentUserId) {
      toast.error(
        "Trebuie să fii autentificat pentru a putea promova un anunț."
      );
      return;
    }
    setShowPromoteModal(true);
  };

  // Fetch user ads if user is logged in
  useEffect(() => {
    const fetchUserAds = async () => {
      if (!currentUserId) return; // Only fetch if user is logged in
      try {
        const q = query(
          collection(firestore, "ads"),
          where("uid", "==", currentUserId)
        );
        const snapshot = await getDocs(q);
        const ads = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const nonPromotedAds = ads.filter((ad) => !ad.promoted); // Exclude promoted ads
        setUserAds(nonPromotedAds);
      } catch (error) {
        console.error("Error fetching user ads:", error);
      }
    };

    fetchUserAds();
  }, [currentUserId]);

  const handleSaveChanges = (updatedService) => {
    // Update the local promoted services list with the edited service
    const updatedPromotedServices = promotedServices.map((service) =>
      service.id === updatedService.id ? updatedService : service
    );
    setUserAds(updatedPromotedServices);
    setIsEditModalOpen(false);
  };

  return (
    <div className="promoted-section">
      <div className="promote-header">
        <h2 className="promote-title">{t("promoted.header")}</h2>
        <button className="promote-button" onClick={handlePromoteClick}>
          {t("promoted.button")}
        </button>
      </div>
      <div className="promoted-container">
        {promotedServices.length === 0 ? (
          <p>No promoted ads found</p>
        ) : (
          promotedServices.map((service, index) => {
            const isOwner = currentUserId === service.uid;
            const categoryImage = getCategoryImage(service.category);
            const isFavorite =
              Array.isArray(favorites) && favorites.includes(service.id);

            return (
              <div
                key={`${service.id}-${index}`}
                className={`promoted-card ${isOwner ? "owner-card" : ""}`}
                style={{
                  cursor: !currentUserId || isOwner ? "not-allowed" : "pointer",
                }}>
                <span className="promoted-badge"></span>
                {isOwner && (
                  <span className="owner-badge">{t("promoted.your_ad")}</span>
                )}
                {categoryImage && (
                  <div className="promoted-image-container">
                    <img
                      src={categoryImage}
                      alt={service.category}
                      className="promoted-image"
                    />
                  </div>
                )}
                <div className="promoted-card-content">
                  <h3 className="promoted-card-title">{service.title}</h3>
                  <p className="promoted-card-price">{service.price} RON</p>
                  <p className="promoted-card-location">{service.location}</p>
                </div>
                <div className="card-buttons">
                  {isOwner ? (
                    <button
                      className="edit-button"
                      onClick={() => openEditModal(service)}>
                      {t("promoted.edit")}
                    </button>
                  ) : (
                    <button
                      className="details-button"
                      onClick={() => handleCardClick(service)}>
                      {t("promoted.details")}
                    </button>
                  )}

                  {!isOwner && (
                    <button
                      className={`favorite-button ${
                        isFavorite ? "selected" : ""
                      }`}
                      onClick={() => handleFavoriteClick(service)}
                      style={{
                        backgroundColor: isFavorite ? "#e74c3c" : "transparent",
                        color: isFavorite ? "#fff" : "#e74c3c",
                      }}>
                      {isFavorite ? t("favorite.liked") : t("favorite.like")}
                    </button>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>

      {showPromoteModal && (
        <div
          className="promote-modal-backdrop"
          onClick={() => setShowPromoteModal(false)}>
          <div className="promote-modal" onClick={(e) => e.stopPropagation()}>
            <h2>{t("promoted.promote_modal.title")}</h2>
            <p>{t("promoted.promote_modal.choose_option")}</p>
            <div className="promote-modal-options">
              <button
                onClick={() => {
                  // Navigate to user profile (adjust the path as needed)
                  navigate(`/profile/${currentUserId}`);
                  setShowPromoteModal(false);
                }}
                className="promote-option-btn">
                {t("promoted.promote_modal.new_ad")}
              </button>
              {/* <div className="existing-ads-section">
                <h3>{t("promoted.promote_modal.existing_ad")}</h3>
                {userAds.length > 0 ? (
                  <>
                    <select
                      value={selectedAdId}
                      onChange={(e) => setSelectedAdId(e.target.value)}>
                      <option value="">
                        {t("promoted.promote_modal.select_ad")}
                      </option>
                      {userAds.map((ad) => (
                        <option key={ad.id} value={ad.id}>
                          {ad.title}
                        </option>
                      ))}
                    </select>
                    {selectedAdId && (
                      <div className="stripe-link-container">
                        <button
                          onClick={handlePaymentLink}
                          className="promote-option-stripe stripe-link">
                          {t("promoted.promote_modal.pay_now")}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <p>{t("promoted.promote_modal.no_ads_available")}</p>
                )}
              </div> */}

              <div className="existing-ads-section">
                <h3>{t("promoted.promote_modal.existing_ad")}</h3>
                <div className="coming-soon-wrapper">
                  <p className="coming-soon-badge">Coming Soon</p>
                  <select disabled>
                    <option>{t("promoted.promote_modal.select_ad")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isDetailsModalOpen && selectedService && (
        <Modal
          service={selectedService}
          onClose={() => setIsDetailsModalOpen(false)} // Închide modalul
          currentUserId={currentUserId}
        />
      )}

      {isEditModalOpen && editingService && (
        <EditModal
          service={editingService}
          onClose={closeEditModal}
          onSave={handleSaveChanges}
          setServices={setServices} // Transmite funcția corectă
        />
      )}
    </div>
  );
};

export default PromotedSection;
