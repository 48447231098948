import React from "react";
import { useTranslation } from "react-i18next";
import "../styling/terms.css"; // Use the same styles as the Terms and Conditions modal

const PrivacyPolicyModal = ({ showModal, closeModal }) => {
  const { t } = useTranslation();

  if (!showModal) return null;

  return (
    <div
      className={`modal-overlay-terms ${showModal ? "show" : ""}`}
      onClick={closeModal}>
      <div
        className={`modal-content-terms ${showModal ? "show" : ""}`}
        onClick={(e) => e.stopPropagation()}>
        <h2>{t("privacyPolicy.title")}</h2>
        <p>{t("privacyPolicy.intro")}</p>

        <h3>{t("privacyPolicy.dataCollection.title")}</h3>
        <p>{t("privacyPolicy.dataCollection.content")}</p>

        <h3>{t("privacyPolicy.dataUsage.title")}</h3>
        <p>{t("privacyPolicy.dataUsage.content")}</p>

        <h3>{t("privacyPolicy.userRights.title")}</h3>
        <p>{t("privacyPolicy.userRights.content")}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
