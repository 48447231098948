import { useParams, Link } from "react-router-dom";
import articles from "../../data/articles.json";
import "../../styling/Article.css";

const Article = () => {
  const { categoryName, title } = useParams();
  const article = articles.find(
    (article) => article.category === categoryName && article.slug === title
  );

  if (!article) {
    return (
      <div className="article-container">
        <h1 className="article-not-found">Articolul nu a fost găsit</h1>
        <p className="not-found-message">
          Ne pare rău, dar nu am putut găsi articolul pe care îl căutați.
        </p>
      </div>
    );
  }

  const shareToFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    window.open(facebookUrl, "_blank");
  };

  const shareToWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${window.location.href}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="article-container">
      <header className="article-header">
        <h1 className="article-title">{article.title}</h1>
        <div className="article-meta">
          <Link to={`/blog/${categoryName}`} className="article-category">
            {categoryName.replace("-", " ").toUpperCase()}
          </Link>
          <span className="article-date"> | Publicat pe: {article.date}</span>
        </div>
        <div className="article-share">
          <button className="share-button" onClick={shareToFacebook}>
            Distribuie pe Facebook
          </button>
          <button className="share-button" onClick={shareToWhatsApp}>
            Trimite pe WhatsApp
          </button>
        </div>
      </header>
      <img src={article.image} alt={article.title} className="article-image" />
      <article className="article-content">
        {article.content.split("\n").map((paragraph, index) => (
          <p key={index} className="article-paragraph">
            {paragraph}
          </p>
        ))}
      </article>
    </div>
  );
};

export default Article;
