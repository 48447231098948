import {
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
  signInWithPopup,
  getAuth,
} from "firebase/auth";
import { addUserToFirestore } from "./firestoreService"; // Importă funcțiile create
import { toast } from "react-toastify";

// Funcție unică pentru autentificare
export const handleLogin = async (navigate) => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  try {
    await setPersistence(auth, browserLocalPersistence); // Persistență sesiune
    const result = await signInWithPopup(auth, provider);

    // Adaugă utilizatorul în Firestore
    await addUserToFirestore(result.user);


    // Navighează către profilul utilizatorului după autentificare
    navigate(`/profile/${result.user.uid}`);
  } catch (error) {
    console.error("Error la autentificare:", error);
    toast.error("Eroare la autentificare, încearcă din nou!");
  }
};

// Funcție unică pentru deconectare
export const handleLogout = async (navigate) => {
  const auth = getAuth(); // Adaugă getAuth pentru a obține instanța de autentificare
  try {
    await auth.signOut(); // Deconectează utilizatorul din Firebase
    navigate("/"); // Navigăm la pagina principală sau unde dorești
    toast.success("Te-ai deconectat cu succes!");
  } catch (error) {
    console.error("Error la deconectare:", error);
    toast.error("Eroare la deconectare!");
  }
};
