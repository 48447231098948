import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import "../styling/filters.css";

const Filters = ({
  categories,
  onSearchChange,
  onPriceChange,
  fetchPriceRange,
  minPrice,
  maxPrice,
}) => {
  const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);
  const [currentRange, setCurrentRange] = useState([minPrice, maxPrice]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const getPriceRange = async () => {
      const [minPriceFromDB, maxPriceFromDB] = await fetchPriceRange();
    
      setPriceRange([minPriceFromDB, maxPriceFromDB]);
      setCurrentRange([minPriceFromDB, maxPriceFromDB]);
    };
    getPriceRange();
  }, [fetchPriceRange]);

  const handlePriceChange = (value, index) => {
    const updatedRange = [...currentRange];
    updatedRange[index] = Number(value);

    if (updatedRange[0] <= updatedRange[1]) {
      setCurrentRange(updatedRange);
      onPriceChange(updatedRange);
    }
  };

const handleCategoryChange = (selectedCategory) => {
  setSelectedCategory(selectedCategory);
  navigate(`/services/${selectedCategory}`);
};


const handleSearchChange = (text) => {
  setSearchText(text);
  onSearchChange(text); // Pass the search text to the parent component
};

  const sortedCategories = categories.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div className="filters-search">
      {/* Search Bar */}
      <div className="filter-item search-bar">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder="Search"
          className="filter-input-search"
          value={searchText}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>

      {/* Category Selector */}
      <div className="filter-item-search">
        <select
          className="filter-select-search"
          value={selectedCategory}
          onChange={(e) => handleCategoryChange(e.target.value)}>
          <option value="">All Categories</option>
          {sortedCategories.map((category, index) => (
            <option key={index} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      {/* Price Range Selector */}
      <div className="price-range">
        <div className="price-input-container">
          <div className="price-input-group">
            <label className="input-label" htmlFor="min-price">
              Min
            </label>
            <input
              id="min-price"
              type="number"
              min={priceRange[0]}
              max={priceRange[1]}
              value={currentRange[0]}
              onChange={(e) => handlePriceChange(e.target.value, 0)}
              placeholder="Min Price"
              className="price-input"
            />
          </div>
          <div className="price-input-group">
            <label className="input-label" htmlFor="max-price">
              Max
            </label>
            <input
              id="max-price"
              type="number"
              min={priceRange[0]}
              max={priceRange[1]}
              value={currentRange[1]}
              onChange={(e) => handlePriceChange(e.target.value, 1)}
              placeholder="Max Price"
              className="price-input"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
