import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  collection,
  query,
  addDoc,
  doc,
  where,
  deleteDoc,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { firestore } from "../data/firebase.js";
import categoriesData from "../data/categories.json";
import PromotedSection from "../components/PromotedSection.jsx";
import RegularSection from "../components/RegularSection.jsx";
import Filters from "../components/Filters.jsx";
import Modal from "../components/Modal.jsx";
import { getAuth } from "firebase/auth";
import "../styling/services.css";
import { toast } from "react-toastify";

const Services = ({ currentUserId }) => {
  // State Variables
  const [ads, setAds] = useState([]);
  const [promotedServices, setPromotedServices] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [priceRange, setPriceRange] = useState([50, 1000]);
  const [priceLimits, setPriceLimits] = useState([50, 1000]);
  const [totalAds, setTotalAds] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [favorites, setFavorites] = useState([]);

  const servicesPerPage = 10; // Number of services per page
  const servicesRef = useRef(null);
  const auth = getAuth();
  const { category } = useParams();

  // Normalize Strings for Search
  const normalizeString = (str) =>
    str
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

  const fetchPriceRange = useCallback(async () => {
    try {
      const adsCollection = collection(firestore, "ads");
      const querySnapshot = await getDocs(adsCollection);

      let minPrice = Infinity;
      let maxPrice = -Infinity;

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (typeof data?.price === "number") {
          if (data.price < minPrice) minPrice = data.price;
          if (data.price > maxPrice) maxPrice = data.price;
        }
      });

      if (minPrice === Infinity || maxPrice === -Infinity) {
        return [50, 1000]; // Default range if no prices are found
      }

      return [minPrice, maxPrice];
    } catch (error) {
      console.error("Error fetching price range:", error);
      return [50, 1000]; // Default range on error
    }
  }, []);

  // Fetch All Ads
  const fetchAllAds = useCallback(async () => {
    try {
      // Log current timestamp
      const now = Timestamp.now();

      // Firestore query
     let q = query(
       collection(firestore, "ads"),
       where("validity", ">=", now),
       where("status", "in", ["posted", "accepted"])
     );

      // Fetch ads
      const querySnapshot = await getDocs(q);

      // Check if any ads are fetched
      if (querySnapshot.empty) {
        console.warn("No ads found with the given query.");
      }

      // Map and log fetched ads
      const allAds = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
        };
      });

      // Update state
      setAds(allAds);

      // Handle price limits
      const prices = allAds
        .map((ad) => ad.price)
        .filter((price) => typeof price === "number");
      if (prices.length > 0) {
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        setPriceLimits([minPrice, maxPrice]);
        setPriceRange([minPrice, maxPrice]);
      } else {
        console.warn("No valid prices found in ads.");
        setPriceLimits([50, 1000]); // Default price range
        setPriceRange([50, 1000]);
      }
    } catch (error) {
      console.error("Error fetching ads:", error);
      setPriceLimits([50, 1000]);
      setPriceRange([50, 1000]);
    }
  }, []);

  // ====================
  // Handle Favorites
  // ====================

  const handleFavoriteClick = async (service) => {
    if (!currentUserId) {
      toast.error(
        "Trebuie să fii autentificat pentru a putea adăuga la favorite."
      );
      return;
    }

    if (!service?.id) {
      console.error("Eroare: ID-ul anunțului este invalid sau inexistent.");
      toast.error("Eroare: ID-ul anunțului este invalid.");
      return;
    }

    try {
      const favoritesCollection = collection(firestore, "favorites");

      // Check if the ad is already a favorite
      const favoriteQuery = query(
        favoritesCollection,
        where("userId", "==", currentUserId),
        where("adId", "==", service.id)
      );

      const snapshot = await getDocs(favoriteQuery);

      if (!snapshot.empty) {
        // Remove the ad from favorites
        const favoriteDocId = snapshot.docs[0].id;
        const favoriteDocRef = doc(firestore, "favorites", favoriteDocId);
        await deleteDoc(favoriteDocRef);

        // Update the local state
        setFavorites((prevFavorites) =>
          prevFavorites.filter((id) => id !== service.id)
        );

        toast.info("Anunțul a fost eliminat din favorite.");

        // Track favorite removal event
        if (window.gtag) {
          window.gtag("event", "remove_favorite", {
            event_category: "Favorites",
            event_label: `Ad ID: ${service.id}`,
          });
        }
      } else {
        // Add the ad to favorites
        const favoriteData = {
          userId: currentUserId,
          adId: service.id,
          ownerUid: service.uid || null,
          title: service.title || "Fără titlu",
          price: service.price || 0,
          timestamp: new Date(),
        };

        await addDoc(favoritesCollection, favoriteData);

        // Update the local state
        setFavorites((prevFavorites) => [...prevFavorites, service.id]);

        toast.success("Anunțul a fost adăugat la favorite.");
        // Track favorite addition event
        if (window.gtag) {
          window.gtag("event", "add_favorite", {
            event_category: "Favorites",
            event_label: `Ad ID: ${service.id}`,
          });
        }
      }
    } catch (error) {
      console.error("Eroare la actualizarea favoritului:", error);
      toast.error("Eroare la actualizarea favoritului. Încearcă din nou.");
    }
  };

  const applyFilters = useCallback(() => {
    let filtered = [...ads]; // Start with all ads

    // Filter by search term
    if (searchTerm) {
      const normalizedSearchTerm = normalizeString(searchTerm);
      filtered = filtered.filter((ad) =>
        normalizeString(ad.title || "").includes(normalizedSearchTerm)
      );
    }

    // Filter by category
    if (selectedCategory) {
      filtered = filtered.filter((ad) => ad.category === selectedCategory);
    }

    // Filter by price range
    if (priceRange.length === 2) {
      filtered = filtered.filter(
        (ad) => ad.price >= priceRange[0] && ad.price <= priceRange[1]
      );
    }

    // Update both filteredAds and promotedServices
    setFilteredAds(filtered);

    const promoted = filtered.filter((ad) => ad.promoted === true);
    setPromotedServices(promoted);

    setTotalAds(filtered.length); // Update total count for pagination
  }, [ads, searchTerm, selectedCategory, priceRange]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  useEffect(() => {
    fetchAllAds();
  }, [fetchAllAds]);

  // Fetch Promoted Services
  const fetchPromotedServices = useCallback(() => {
    const promoted = filteredAds.filter((ad) => ad.promoted === true);
    setPromotedServices(promoted);
  }, [filteredAds]);

  useEffect(() => {
    fetchPromotedServices();
  }, [fetchPromotedServices]);

  // Get Paginated Services
  const getPaginatedServices = useCallback(() => {
    const startIndex = (currentPage - 1) * servicesPerPage;
    const endIndex = startIndex + servicesPerPage;
    const paginated = filteredAds.slice(startIndex, endIndex);
    return paginated;
  }, [filteredAds, currentPage, servicesPerPage]);

  // Handle Page Change
  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalAds / servicesPerPage)) {
      setCurrentPage(page);
      if (servicesRef.current) {
        servicesRef.current.scrollIntoView({ behavior: "smooth" });
      } // Track pagination event
      if (window.gtag) {
        window.gtag("event", "paginate", {
          event_category: "Pagination",
          event_label: `Page: ${page}`,
        });
      }
    }
  };

  // Handle Search Change
  const handleSearchChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to first page on search

    // Track search event
    if (window.gtag) {
      window.gtag("event", "search_ads", {
        event_category: "Search",
        event_label: `Search Term: ${value}`,
      });
    }
  };

  // Handle Category Change
  const handleCategoryChange = (cat) => {
    setSelectedCategory(cat);
    setCurrentPage(1); // Reset to first page on category change
    if (window.gtag) {
      window.gtag("event", "filter_category", {
        event_category: "Filters",
        event_label: `Category: ${cat}`,
      });
    }
  };

  // Handle Price Change
  const handlePriceChange = (range) => {
    setPriceRange(range);
    setCurrentPage(1); // Reset to first page on price change

    // Track price range filter event
    if (window.gtag) {
      window.gtag("event", "filter_price_range", {
        event_category: "Filters",
        event_label: `Price Range: ${range[0]} - ${range[1]}`,
      });
    }
  };

  // Fetch Ads on Component Mount
  useEffect(() => {
    fetchAllAds();
  }, [fetchAllAds]);

  // Apply Filters when Inputs Change
  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  // Fetch Promoted Services when Filters Change
  useEffect(() => {
    fetchPromotedServices();
  }, [fetchPromotedServices]);

  // Set Selected Category from URL
  useEffect(() => {
    setSelectedCategory(category || "");
  }, [category]);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!currentUserId) return;

      try {
        const favoritesCollection = collection(firestore, "favorites");
        const favoriteQuery = query(
          favoritesCollection,
          where("userId", "==", currentUserId)
        );

        const snapshot = await getDocs(favoriteQuery);
        const favoriteIds = snapshot.docs.map((doc) => doc.data().adId);
        setFavorites(favoriteIds); // Update the favorites state with ad IDs
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    };

    fetchFavorites();
  }, [currentUserId]);

  return (
    <div id="services" ref={servicesRef} className="services-page">
      <Filters
        categories={categoriesData.categories}
        onSearchChange={handleSearchChange}
        onCategoryChange={handleCategoryChange}
        fetchPriceRange={fetchPriceRange}
        onPriceChange={handlePriceChange}
        minPrice={priceLimits[0] || 50}
        maxPrice={priceLimits[1] || 1000}
      />

      <PromotedSection
        promotedServices={promotedServices}
        onCardClick={(service) => setSelectedService(service)}
        currentUserId={auth.currentUser?.uid}
        favorites={favorites} // Pass the favorites array
        handleFavoriteClick={(service) =>
          handleFavoriteClick(
            service,
            auth.currentUser?.uid,
            favorites,
            setFavorites
          )
        }
      />
      <RegularSection
        regularServices={getPaginatedServices()} // Paginated ads
        setServices={setAds} // Transmitere a funcției pentru actualizare
        currentPage={currentPage}
        totalAds={totalAds}
        favorites={favorites} // Pass the favorites array
        servicesPerPage={servicesPerPage}
        onCardClick={(service) => setSelectedService(service)}
        onPageChange={handlePageChange}
        currentUserId={auth.currentUser?.uid}
        handleFavoriteClick={(service) =>
          handleFavoriteClick(
            service,
            auth.currentUser?.uid,
            favorites,
            setFavorites
          )
        }
      />

      {isModalOpen && selectedService && (
        <Modal
          service={selectedService}
          onClose={() => setIsModalOpen(false)}
          currentUserId={auth.currentUser?.uid}
        />
      )}
    </div>
  );
};

export default Services;
