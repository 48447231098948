import React, { useEffect, useState, useCallback } from "react";
import {
  collection,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  onSnapshot,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { firestore } from "../data/firebase";
import { getAuth } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styling/moderator.css"; // Include styling-ul separat

const ModeratorPanel = () => {
  const [users, setUsers] = useState([]);
  const [ads, setAds] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModerator, setIsModerator] = useState(false);

  // Stările pentru fiecare bară de căutare
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [adSearchQuery, setAdSearchQuery] = useState("");
  const [taskSearchQuery, setTaskSearchQuery] = useState("");

  // State pentru ascundere
  const [hideReason, setHideReason] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEntityId, setCurrentEntityId] = useState(null);
  const [currentEntityType, setCurrentEntityType] = useState(null);

  const auth = getAuth();

  const fetchUsers = useCallback(async () => {
    const usersCollection = collection(firestore, "users");
    const userSnapshot = await getDocs(usersCollection);
    const userList = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(userList);
  }, []);

  const fetchAds = useCallback(() => {
    const adsCollection = collection(firestore, "ads");
    const unsubscribe = onSnapshot(adsCollection, (snapshot) => {
      const adList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAds(adList);
    });
    return () => unsubscribe();
  }, []);

  const fetchTasks = useCallback(async () => {
    const tasksQuery = query(
      collection(firestore, "tasks"),
      where("status", "==", "unresolved")
    );
    const tasksSnapshot = await getDocs(tasksQuery);
    const taskList = tasksSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTasks(taskList);
  }, []);

  const checkIfModerator = useCallback(async (uid) => {
    const userDocRef = doc(firestore, "users", uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      if (userDoc.data().role === "moderator") {
        setIsModerator(true);
      } else {
        setIsModerator(false);
      }
    } else {
      console.error("User document does not exist");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          await checkIfModerator(user.uid);
          await fetchUsers();
          await fetchAds();
          await fetchTasks();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.error("No user logged in");
      }
      setLoading(false);
    };

    fetchData();
  }, [checkIfModerator, fetchUsers, fetchAds, fetchTasks, auth.currentUser]);

  useEffect(() => {}, [isModerator]);

  if (loading) return <div>Loading...</div>;

  if (!isModerator) {
    return <div>Nu ai permisiunea de a accesa această pagină.</div>;
  }

  const openHideModal = (id, entityType) => {
    setCurrentEntityId(id);
    setCurrentEntityType(entityType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setHideReason("");
    setCurrentEntityId(null);
    setCurrentEntityType(null);
  };

  const handleHide = async () => {
    if (!hideReason.trim()) {
      toast.error("Te rog să oferi un motiv pentru ascundere.");
      return;
    }

    try {
      const currentDate = new Date();
      const entityRef = doc(firestore, currentEntityType, currentEntityId);

      // Actualizează documentul pentru ascundere și motiv
      await updateDoc(entityRef, {
        isHidden: true,
        hideReason,
        hiddenAt: currentDate,
      });

      // Adaugă o notificare pentru admin
      await addDoc(collection(firestore, "notifications"), {
        entityId: currentEntityId,
        entityType: currentEntityType,
        message: `Entitatea ${
          currentEntityType === "users" ? "utilizator" : "anunț"
        } a fost ascunsă cu motivul: ${hideReason}`,
        timestamp: currentDate,
        isRead: false,
      });

      toast.success("Entitatea a fost ascunsă cu succes.");
    } catch (error) {
      toast.error("A apărut o eroare la ascunderea entității.");
    }

    closeModal();
  };

  // Funcții de filtrare pentru fiecare secțiune
  const filterUsers = () => {
    return users.filter(
      (user) =>
        (user.name || "")
          .toLowerCase()
          .includes(userSearchQuery.toLowerCase()) ||
        (user.email || "")
          .toLowerCase()
          .includes(userSearchQuery.toLowerCase()) ||
        (user.phone || "").toLowerCase().includes(userSearchQuery.toLowerCase())
    );
  };

  const filterAds = () => {
    return ads.filter(
      (ad) =>
        (ad.title || "").toLowerCase().includes(adSearchQuery.toLowerCase()) ||
        (ad.location || "")
          .toLowerCase()
          .includes(adSearchQuery.toLowerCase()) ||
        (ad.category || "").toLowerCase().includes(adSearchQuery.toLowerCase())
    );
  };

  const filterTasks = () => {
    return tasks.filter(
      (task) =>
        (task.description || "")
          .toLowerCase()
          .includes(taskSearchQuery.toLowerCase()) ||
        (task.priority || "")
          .toLowerCase()
          .includes(taskSearchQuery.toLowerCase())
    );
  };

  return (
    <div className="moderator-panel">
      <div className="content-moderator">
        <ToastContainer position="bottom-left" />
        <h1>Moderator Panel</h1>
        <div className="top-moderator">
          <div className="all-users-moderator moderator-section">
            <h2>Toți utilizatorii</h2>
            <input
              type="text"
              placeholder="Caută utilizatori după nume, email, telefon..."
              value={userSearchQuery}
              onChange={(e) => setUserSearchQuery(e.target.value)}
              className="search-bar-moderator"
            />
            <ul className="ul-moderator">
              {filterUsers().map((user) => (
                <li className="li-moderator" key={user.id}>
                  {user.name || "Utilizator necunoscut"} - {user.email}
                  <button
                    className="hide-moderator"
                    onClick={() => openHideModal(user.id, "users")}>
                    Ascunde
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="all-ads-moderator moderator-section">
            <h2>Toate anunțurile</h2>
            <input
              type="text"
              placeholder="Caută anunțuri după titlu, locație, categorie..."
              value={adSearchQuery}
              onChange={(e) => setAdSearchQuery(e.target.value)}
              className="search-bar-moderator"
            />
            <ul className="ul-moderator">
              {filterAds().map((ad) => (
                <li className="li-moderator" key={ad.id}>
                  {ad.title} - {ad.location}
                  <button
                    className="hide-moderator"
                    onClick={() => openHideModal(ad.id, "ads")}>
                    Ascunde
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="moderator-section">
          <h2>Taskuri de rezolvat</h2>
          <input
            type="text"
            placeholder="Caută taskuri după descriere, prioritate..."
            value={taskSearchQuery}
            onChange={(e) => setTaskSearchQuery(e.target.value)}
            className="search-bar-moderator"
          />
          <ul className="ul-moderator">
            {filterTasks().map((task) => (
              <li className="li-moderator" key={task.id}>
                {task.description} - Prioritate: {task.priority}
              </li>
            ))}
          </ul>
        </div>

        {/* Modal pentru motivul de ascundere */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Motivul ascunderii</h2>
              <textarea
                value={hideReason}
                onChange={(e) => setHideReason(e.target.value)}
                placeholder="Introduceți motivul pentru ascundere..."
              />
              <div className="modal-buttons">
                <button onClick={handleHide}>Confirmă</button>
                <button onClick={closeModal}>Anulează</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModeratorPanel;
