import { Link } from "react-router-dom";
import articles from "../../data/articles.json";
import "../../styling/Blog.css";

const Blog = () => {
  return (
    <div className="blog-container">
      {/* Hero Section with Video */}
      <div className="blog-hero">
        <video className="hero-video" autoPlay loop muted>
          <source
            src="https://videos.pexels.com/video-files/855427/855427-hd_1920_1080_25fps.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="hero-overlay">
          <h1 className="hero-title">Aidly Connect</h1>
          <p className="hero-subtitle">
            Resurse, inspirație și soluții pentru cei care caută să își atingă
            obiectivele – împreună.
          </p>
        </div>
      </div>

      {/* Articles Section */}
      <div className="blog-articles">
        <h2 className="section-title">Articole Recente</h2>
        <div className="articles-grid">
          {articles.map((article, index) => (
            <Link
              to={`/blog/${article.category}/${article.slug}`}
              key={index}
              className="article-card">
              <h3 className="article-title">{article.title}</h3>
              <p className="article-meta">
                Categorie: {article.category.replace("-", " ")}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
