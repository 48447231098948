import React from "react";
import { Link } from "react-router-dom";
import "../styling/notFound.css";

const NotFoundPage = () => {
  return (
    <div className="not-found-wrapper">
      <h1 className="error-code">404</h1>
      <div className="not-found-content">
        <p className="error-text">Oops! Page Not Found</p>
        <p className="error-description">
          It seems like you've wandered off. Let's guide you back to the right
          path!
        </p>
        <Link to="/" className="home-button">
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
