import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styling/profile.css";
import {
  FaBell,
  FaEdit,
  FaTrashAlt,
  FaPlusCircle,
  FaEnvelope,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import forbiddenWordsData from "../data/forbiddenWords.json";

// import { setLogLevel } from "firebase/app";
// setLogLevel("debug");

import {
  deleteDoc,
  collection,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  orderBy,
  onSnapshot,
  query,
  where,
  getDocs,
  getDoc,
  Timestamp,
} from "firebase/firestore";

import { firestore } from "../data/firebase";
import categoriesData from "../data/categories.json"; // încă folosit pentru sortare
import ProfileImage1 from "../assets/mascota.webp";
import Sad from "../assets/sad.png";
import UploadPhoto from "../utils/UploadPhoto";
import locationsData from "../data/locations.json";
// ---------- importuri helper-e -----------
import {
  detectCategory,
  generateSuggestions,
} from "../helpers/categoryHelpers";
import { t } from "i18next";

// Setăm root-ul pentru Modal
Modal.setAppElement("#root");

const Profile = () => {
  const { uid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // --------------------------------------------------
  // State
  // --------------------------------------------------
  const [user, setUser] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(ProfileImage1);
  // eslint-disable-next-line
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

  // State extrase din codul original (Profile)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [paymentResult, setPaymentResult] = useState(null); // Correctly initialize state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [messages, setMessages] = useState([]);

  const [currentAd, setCurrentAd] = useState(null);
  const [isDoneModalOpen, setIsDoneModalOpen] = useState(false);
  const [isUndoneModalOpen, setIsUndoneModalOpen] = useState(false);
  // eslint-disable-next-line
  const [forbiddenWordsList, setForbiddenWordsList] = useState([]);

  // Recenzie / undone reason
  const [reviewData, setReviewData] = useState({
    rating: 0,
    review: "",
  });
  const [undoneReason, setUndoneReason] = useState("");
  const [currentRole, setCurrentRole] = useState("");

  const [ads, setAds] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  // Filtre / search
  const [searchTitle, setSearchTitle] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  // notificații
  // eslint-disable-next-line
  const [currentNotification, setCurrentNotification] = useState(null);

  // taburi
  const [showAds, setShowAds] = useState(false);
  const [showInProgressAds, setShowInProgressAds] = useState(false);
  const [inProgressAds, setInProgressAds] = useState([]);
  const [completedAds, setCompletedAds] = useState([]);
  const [showCompletedAds, setShowCompletedAds] = useState(false);

  // Favorite ads
  const [favoriteAds, setFavoriteAds] = useState([]);
  const [showFavoriteAds, setShowFavoriteAds] = useState(false);

  // formData anunț
  const [formData, setFormData] = useState({
    title: "",
    location: "",
    price: 50,
    promoted: false,
    uid: "", // Use empty string if `user` is null
    validity: null,
    category: "",
    description: "",
    adId: null,
  });

  useEffect(() => {
    try {
      setForbiddenWordsList(forbiddenWordsData.forbiddenWords || []);
    } catch (error) {
      console.error("Error loading forbidden words:", error);
    }
  }, []);

  // --------------------------------------------------
  // Auth: onAuthStateChanged
  // --------------------------------------------------
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Fetch additional user data from Firestore, if needed
        try {
          const userDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          if (userDoc.exists()) {
            const uData = userDoc.data();
            setFormData((prev) => ({
              ...prev,
              uid: currentUser.uid, // Ensure uid is set
              displayName: uData.displayName || "User",
              phoneNumber: uData.phoneNumber || "Not Provided",
              photoURL: uData.photoURL || "default-avatar-url",
            }));
            setProfilePhoto(uData.photoURL || "default-avatar-url");

            if (
              !uData.phoneNumber ||
              uData.phoneNumber === "Not Provided" ||
              uData.phoneNumber === "n/a" ||
              uData.phoneNumber === "null"
            ) {
              setIsEditingName(true); // Setează modalul să se deschidă
            }
          } else {
            console.warn("User document not found in Firestore.");
            setFormData((prev) => ({
              ...prev,
              uid: currentUser.uid, // Ensure uid is set
              displayName: currentUser.displayName || "User",
              phoneNumber: "Not Provided",
              photoURL: currentUser.photoURL || "default-avatar-url",
            }));
            setProfilePhoto(currentUser.photoURL || "default-avatar-url");
            setIsEditingName(true); // Force open the modal
          }
        } catch (error) {
          console.error("Error fetching user data from Firestore:", error);
          setFormData((prev) => ({
            ...prev,
            uid: currentUser.uid, // Ensure uid is set
            displayName: currentUser.displayName || "User",
            phoneNumber: "Not Provided",
            photoURL: currentUser.photoURL || "default-avatar-url",
          }));
          setProfilePhoto(currentUser.photoURL || "default-avatar-url");
          setIsEditingName(true); // Force open the modal
        }
      } else {
        console.warn("No authenticated user.");
        setUser(null);
        setFormData((prev) => ({
          ...prev,
          uid: "", // Clear uid when user logs out
          displayName: "User",
          phoneNumber: "",
          photoURL: "default-avatar-url",
        }));
        setProfilePhoto("default-avatar-url");
      }
    });

    return () => unsubscribe();
  }, []);

  // --------------------------------------------------
  // Check if user has phone
  // --------------------------------------------------

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser) {
        setIsModalOpen(true); // Deschide modalul dacă utilizatorul nu este autentificat
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  // --------------------------------------------------
  // Forbidden Words Prevent
  // --------------------------------------------------

  const normalizeString = (str) =>
    str
      ?.normalize("NFD") // Normalize to decompose diacritic characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .toLowerCase();

  const containsForbiddenWords = (text, forbiddenWordsList) => {
    if (!forbiddenWordsList || forbiddenWordsList.length === 0) {
      console.error("Lista de cuvinte interzise este goală.");
      return false;
    }

    const normalizedText = normalizeString(text || "");
    const forbiddenWords = forbiddenWordsList.map(normalizeString);

    return forbiddenWords.some((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi"); // Whole word match
      return regex.test(normalizedText);
    });
  };

  // --------------------------------------------------
  // handleSubmit
  // --------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    const forbiddenWordsList = forbiddenWordsData?.forbiddenWords || [];

    // Generate or reuse adId
    const adRef = formData.adId
      ? doc(firestore, "ads", formData.adId) // Use existing adId
      : doc(collection(firestore, "ads")); // Generate new document reference
    const adId = formData.adId || adRef.id;

    try {
      // Early validation of `adId`
      if (!adId || typeof adId !== "string") {
        throw new Error(`Ad ID is invalid: ${adId}`);
      }

      // Validation logic
      if (!formData.title.trim()) {
        toast.error("Titlul este obligatoriu!");
        return;
      }

      if (!formData.location.trim()) {
        toast.error("Locația este obligatorie!");
        return;
      }

      if (!formData.price || formData.price <= 0) {
        toast.error("Prețul trebuie să fie minim 1!");
        return;
      }

      if (!formData.validity || isNaN(new Date(formData.validity))) {
        toast.error("Valabilitatea este invalidă. Selectați o dată validă.");
        return;
      }

      if (!formData.category) {
        toast.error("Vă rugăm să selectați o categorie!");
        return;
      }

      if (containsForbiddenWords(formData.title, forbiddenWordsList)) {
        toast.error("Titlul conține cuvinte interzise.");
        return;
      }

      if (containsForbiddenWords(formData.description, forbiddenWordsList)) {
        toast.error("Descrierea conține cuvinte interzise.");
        return;
      }

      if (!user?.uid) {
        toast.error(
          "Utilizator neautentificat. Încercați să vă conectați din nou."
        );
        return;
      }

      // Promoted ad logic
      if (formData.promoted) {
        localStorage.setItem("adData", JSON.stringify(formData));
        try {
          const response = await fetch(
            "https://us-central1-ajutorescu-d7fab.cloudfunctions.net/createCheckoutSession",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ uid: user?.uid }),
            }
          );

          if (!response.ok) {
            throw new Error(
              `Payment initiation failed with status ${response.status}`
            );
          }

          const session = await response.json();

          if (session.url) {
            window.location.href = session.url;
            toast.info("Redirecting to Stripe for payment...");
            return; // Stop further execution until payment is complete
          } else {
            toast.error("Unable to create payment session.");
            return;
          }
        } catch (error) {
          console.error("Error initiating payment:", error);
          toast.error(`An error occurred: ${error.message}`);
          return;
        }
      }

      // Final Ad Data
      const finalAd = {
        ...formData,
        uid: user?.uid || formData.uid,
        adId, // Use validated adId
        status: "posted",
        timestamp: Timestamp.now(),
        ownerCompleted: false,
        taskerCompleted: false,
        validity: Timestamp.fromDate(new Date(formData.validity)),
        promoted: formData.promoted, // Flag for promoted ads
      };

      console.table(finalAd);

      // Validate finalAd fields
      Object.entries(finalAd).forEach(([key, value]) => {
        if (value === null || value === undefined) {
          console.warn(`Field "${key}" is null or undefined.`);
        }
      });

      // Save to Firestore
      await setDoc(adRef, finalAd);

      // Notify success
      toast.success(
        formData.promoted
          ? "Anunț creat și promovat cu succes!"
          : "Anunț creat cu succes!"
      );

      // Reset formData
      setFormData({
        title: "",
        location: "",
        uid: user?.uid || "",
        price: 50,
        validity: null,
        category: "",
        description: "",
        adId: null, // Reset adId
        promoted: false,
      });

      setIsAddModalOpen(false);
      await fetchUserAds();
    } catch (error) {
      console.error("Eroare la crearea anunțului:", error);
      toast.error("Eroare la crearea anunțului.");
    }
  };

  // --------------------------------------------------
  // handleChange
  // --------------------------------------------------
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name === "title") {
      // Detectăm ce categorie iese:
      const detectedCat = detectCategory(value);
      // Dacă e "Altele", lăsăm category = "" => user e forțat să aleagă manual
      if (detectedCat === "Altele") {
        updatedFormData.category = "";
        updatedFormData.description = "";
      } else {
        updatedFormData.category = detectedCat;
        updatedFormData.description = generateSuggestions(detectedCat);
      }
    }
    if (name === "price") {
      updatedFormData.price = value === "" ? "" : Number(value);
    }
    setFormData(updatedFormData);
  };

  // --------------------------------------------------
  // Preluare anunțuri postate ale userului
  // --------------------------------------------------
  const fetchUserAds = useCallback(async () => {
    if (!uid) return;
    try {
      const adsRef = collection(firestore, "ads");
      const q = query(
        adsRef,
        where("uid", "==", uid),
        where("status", "==", "posted")
      );
      const snapshot = await getDocs(q);
      setAds(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  }, [uid]);

  // --------------------------------------------------
  // Preluare anunțuri inProgress
  // --------------------------------------------------
  const fetchInProgressAds = useCallback(async () => {
    if (!uid) {
      console.error("UID is undefined. Cannot fetch in-progress ads.");
      return;
    }
    try {
      const adsRef = collection(firestore, "ads");
      const q = query(
        adsRef,
        where("status", "==", "inProgress"),
        where("participants", "array-contains", uid)
      );
      const snapshot = await getDocs(q);

      const adsWithDetails = await Promise.all(
        snapshot.docs.map(async (docSnap) => {
          const adData = { id: docSnap.id, ...docSnap.data() };

          // Fetch owner details
          if (adData.uid) {
            const ownerDoc = await getDoc(doc(firestore, "users", adData.uid));
            if (ownerDoc.exists()) {
              const ownerData = ownerDoc.data();
              adData.ownerName = ownerData.displayName || "Unknown";
              adData.ownerPhone = ownerData.phoneNumber || "Not provided";
              adData.ownerEmail = ownerData.email || "Not provided";
            } else {
              console.warn(`Owner details not found for UID: ${adData.uid}`);
              adData.ownerName = "Unknown";
              adData.ownerPhone = "Not provided";
              adData.ownerEmail = "Not provided";
            }
          }

          // Fetch tasker details
          if (adData.taskerUid) {
            const taskerDoc = await getDoc(
              doc(firestore, "users", adData.taskerUid)
            );
            if (taskerDoc.exists()) {
              const taskerData = taskerDoc.data();
              adData.taskerName = taskerData.displayName || "Unknown";
              adData.taskerPhone = taskerData.phoneNumber || "Not provided";
              adData.taskerEmail = taskerData.email || "Not provided";
            } else {
              console.warn(
                `Tasker details not found for UID: ${adData.taskerUid}`
              );
              adData.taskerName = "Unknown";
              adData.taskerPhone = "Not provided";
              adData.taskerEmail = "Not provided";
            }
          }

          return adData;
        })
      );
      setInProgressAds(adsWithDetails);
    } catch (error) {
      console.error("Error fetching in-progress ads:", error);
    }
  }, [uid]);

  // TESTING

  useEffect(() => {
    const cachedAds = localStorage.getItem("inProgressAds");
    if (cachedAds) {
      setInProgressAds(JSON.parse(cachedAds));
    }
    fetchInProgressAds();
  }, [fetchInProgressAds]);

  useEffect(() => {
    if (inProgressAds.length > 0) {
      localStorage.setItem("inProgressAds", JSON.stringify(inProgressAds));
    }
  }, [inProgressAds]);

  // --------------------------------------------------
  // Notificări
  // --------------------------------------------------
  const notificationsUnsubscribeRef = useRef(null);
  const fetchNotifications = useCallback(() => {
    if (!uid) {
      console.error("Missing UID in fetchNotifications");
      return;
    }
    const notificationsQuery = query(
      collection(firestore, "notifications"),
      where("recipientUid", "==", uid),
      where("isDeleted", "==", false),
      orderBy("timestamp", "desc")
    );

    if (notificationsUnsubscribeRef.current) {
      notificationsUnsubscribeRef.current();
    }
    const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
      const fetchedNotifications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(fetchedNotifications);
      setUnreadCount(fetchedNotifications.filter((n) => !n.isRead).length);
    });

    notificationsUnsubscribeRef.current = unsubscribe;
  }, [uid]);

  useEffect(() => {
    const unsubscribe = fetchNotifications();
    return () => unsubscribe && unsubscribe();
  }, [fetchNotifications]);

  // Preluăm ads user + setăm categorii sortate
  useEffect(() => {
    fetchUserAds();
    const sortedCategories = [...categoriesData.categories].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setCategories(sortedCategories);
  }, [fetchUserAds]);

  // --------------------------------------------------
  // handleOpenAddModal
  // --------------------------------------------------
  const handleOpenAddModal = () => {
    const adRef = doc(collection(firestore, "ads"));
    setFormData({
      title: "",
      location: "",
      price: 50,
      promoted: false,
      validity: null,
      category: "",
      description: "",
      adId: adRef.id,
      uid: user?.uid || "", // Setează uid din utilizatorul autentificat
    });
    setIsAddModalOpen(true);
  };

  // --------------------------------------------------
  // favorite ads
  // --------------------------------------------------
  const synchronizeFavoritesWithAds = useCallback(async () => {
    if (!uid) return;
    try {
      const adsSnapshot = await getDocs(collection(firestore, "ads"));
      const existingAdIds = new Set(adsSnapshot.docs.map((doc) => doc.id));

      const favRef = collection(firestore, "favorites");
      const qFav = query(favRef, where("userId", "==", uid));
      const favoritesSnapshot = await getDocs(qFav);

      const deletionPromises = [];
      favoritesSnapshot.forEach((favoriteDoc) => {
        const favoriteData = favoriteDoc.data();
        if (!existingAdIds.has(favoriteData.adId)) {
          const favoriteRef = doc(firestore, "favorites", favoriteDoc.id);
          deletionPromises.push(deleteDoc(favoriteRef));
        }
      });
      await Promise.all(deletionPromises);
      fetchFavoriteAds();
    } catch (error) {
      console.error("Error synchronizing favorites with ads:", error);
    }
    // eslint-disable-next-line
  }, [uid]);

  const fetchFavoriteAds = useCallback(async () => {
    if (!uid) return;
    try {
      await synchronizeFavoritesWithAds();
      const favoritesRef = collection(firestore, "favorites");
      const qFav = query(favoritesRef, where("userId", "==", uid));
      const snapshot = await getDocs(qFav);

      const adsPromises = snapshot.docs.map(async (favDoc) => {
        const adRef = doc(firestore, "ads", favDoc.data().adId);
        const adSnapshot = await getDoc(adRef);
        if (!adSnapshot.exists()) return null;
        return { id: adSnapshot.id, ...adSnapshot.data() };
      });

      const foundAds = (await Promise.all(adsPromises)).filter(
        (ad) => ad !== null
      );
      setFavoriteAds(foundAds);
    } catch (error) {
      console.error("Error fetching favorite ads:", error);
    }
  }, [uid, synchronizeFavoritesWithAds]);

  useEffect(() => {
    if (showFavoriteAds) {
      fetchFavoriteAds();
    }
  }, [showFavoriteAds, fetchFavoriteAds]);

  // --------------------------------------------------
  // Butoane + toggle ads
  // --------------------------------------------------
  const toggleAdsVisibility = () => {
    setShowAds((prev) => {
      if (!prev) setShowInProgressAds(false);
      return !prev;
    });
  };
  const toggleInProgressAdsVisibility = () => {
    setShowInProgressAds((prev) => {
      if (!prev) setShowAds(false);
      return !prev;
    });
  };

  // --------------------------------------------------
  // handleNameEdit + handleSaveUserInfo
  // --------------------------------------------------
  const handleNameEdit = async () => {
    const effectiveUid = uid || user?.uid;
    if (!effectiveUid) {
      toast.error("User ID is missing. Cannot fetch data.");
      return;
    }
    try {
      const userRef = doc(firestore, "users", effectiveUid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setFormData({
          displayName: userData.displayName || "",
          email: userData.email || "",
          phone: userData.phoneNumber || "", // Ensure this is included
        });
        setIsEditingName(true);
      } else {
        toast.error("User data not found.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data.");
    }
  };

  const handleSaveUserInfo = async () => {
    if (!formData.phone || formData.phone.length < 9) {
      toast.error("Numărul de telefon trebuie să fie valid!");
      return;
    }

    try {
      const userRef = doc(firestore, "users", user.uid);
      await updateDoc(userRef, { phoneNumber: formData.phone });
      toast.success("Numărul de telefon a fost salvat!");
      setIsEditingName(false);
    } catch (error) {
      console.error("Eroare la salvarea numărului de telefon:", error);
      toast.error("Nu am reușit să salvăm numărul de telefon.");
    }
  };

  const handleConfirmSave = () => {
    const userConfirmed = window.confirm(
      "Ești sigur că vrei să salvezi modificările?"
    );
    if (userConfirmed) {
      handleSaveUserInfo();
    }
  };

  // --------------------------------------------------
  // handleDeleteAd
  // --------------------------------------------------
  const [adToDelete, setAdToDelete] = useState(null);
  const confirmDeleteAd = (ad) => {
    setAdToDelete(ad);
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDeleteAd = async () => {
    if (!adToDelete) return;
    try {
      const adRef = doc(firestore, "ads", adToDelete.id);
      await deleteDoc(adRef);
      toast.success("Anunț șters cu succes");
      setAds((prev) => prev.filter((x) => x.id !== adToDelete.id));
      setIsDeleteModalOpen(false);
    } catch (error) {
      toast.error("Eroare la ștergerea anunțului");
      console.error(error);
    }
  };

  // --------------------------------------------------
  // handleEdit
  // --------------------------------------------------
  const setCurrentAdId = (id) => {
    const adToEdit = ads.find((ad) => ad.id === id);
    if (adToEdit) {
      setFormData(adToEdit);
      setIsEditModalOpen(true);
    }
  };

  // --------------------------------------------------
  // In-progress logic
  // --------------------------------------------------
  const handleMarkAsCompleted = async (ad, role) => {
    try {
      const adRef = doc(firestore, "ads", ad.id);
      const updates =
        role === "owner" ? { ownerCompleted: true } : { taskerCompleted: true };
      await updateDoc(adRef, updates);

      const updatedAdSnap = await getDoc(adRef);
      const updatedAd = updatedAdSnap.data();

      setCurrentAd(ad);
      setCurrentRole(role);
      setReviewData({
        rating: 0,
        review: "",
      });
      setIsDoneModalOpen(true);

      if (updatedAd.ownerCompleted && updatedAd.taskerCompleted) {
        await updateDoc(adRef, { status: "finalized" });
        toast.success("Task-ul a fost finalizat complet!");
      } else {
        toast.success(
          `Task-ul a fost marcat ca finalizat de ${
            role === "owner" ? "Owner" : "Tasker"
          }.`
        );
      }
      fetchInProgressAds();
    } catch (error) {
      console.error("Eroare la marcarea ca finalizat:", error);
      toast.error("Eroare la marcarea task-ului ca finalizat.");
    }
  };

  // --------------------------------------------------
  // handleSubmitReview
  // --------------------------------------------------
  const handleSubmitReview = async (ad, role) => {
    const { rating, review } = reviewData;
    if (!rating || !review) {
      toast.error("Toate câmpurile sunt obligatorii!");
      return;
    }
    try {
      const reviewDataToSave = {
        adId: ad.id,
        reviewerUid: user?.uid,
        reviewerName: user?.displayName || "Anonim",
        forUid: role === "owner" ? ad.taskerUid : ad.uid,
        forName: role === "owner" ? ad.taskerName : ad.displayName,
        role,
        rating,
        review,
        timestamp: Timestamp.now(),
      };
      await addDoc(collection(firestore, "reviews"), reviewDataToSave);
      toast.success("Recenzie trimisă cu succes!");
      fetchCompletedAds();
      handleCloseModals();
    } catch (error) {
      console.error("Eroare la trimiterea recenziei:", error);
      toast.error("Eroare la trimiterea recenziei!");
    }
  };

  // --------------------------------------------------
  // handleSubmitUndone
  // --------------------------------------------------
  const handleSubmitUndone = async (ad) => {
    if (!undoneReason.trim()) {
      toast.error("Trebuie să completezi motivul!");
      return;
    }
    try {
      const adRef = doc(firestore, "ads", ad.id);
      await updateDoc(adRef, {
        status: "failed",
        undoneReason,
      });
      toast.success("Motiv trimis cu succes!");
      handleCloseModals();
    } catch (error) {
      toast.error("Eroare la trimiterea motivului!");
    }
  };

  // --------------------------------------------------
  // handleOpenDoneModal & Undone
  // --------------------------------------------------
  // eslint-disable-next-line
  const handleOpenDoneModal = (ad) => {
    setCurrentAd(ad);
    setIsDoneModalOpen(true);
  };
  const handleOpenUndoneModal = (ad) => {
    setCurrentAd(ad);
    setIsUndoneModalOpen(true);
  };
  const handleCloseModals = () => {
    setIsDoneModalOpen(false);
    setIsUndoneModalOpen(false);
  };

  // --------------------------------------------------
  // Notifications
  // --------------------------------------------------
  // eslint-disable-next-line
  const openNotificationModal = () => {
    setIsNotificationModalOpen(true);
    markAllNotificationsAsRead();
  };
  const markAllNotificationsAsRead = async () => {
    try {
      if (!uid) return;
      const notificationsRef = collection(firestore, "notifications");
      const snapshot = await getDocs(
        query(
          notificationsRef,
          where("recipientUid", "==", uid),
          where("isRead", "==", false)
        )
      );
      const updatePromises = snapshot.docs.map((docu) =>
        updateDoc(docu.ref, { isRead: true })
      );
      await Promise.all(updatePromises);
      toast.success("Toate notificările au fost marcate ca citite!");
    } catch (error) {
      console.error("Eroare la marcarea notificărilor:", error);
      toast.error("Eroare la marcarea notificărilor.");
    }
  };

  // --------------------------------------------------
  // handleNotificationAction (finalAccept, decline, etc.)
  // --------------------------------------------------
  const handleNotificationAction = async (notification, action) => {
    try {
      const notificationRef = doc(firestore, "notifications", notification.id);
      const adRef = doc(firestore, "ads", notification.adId);

      if (action === "finalAccept") {
        // Update the approved notification
        await updateDoc(notificationRef, {
          status: "inProgress",
          isDeleted: false,
        });

        // Decline other notifications for the same ad
        const notifsQuery = query(
          collection(firestore, "notifications"),
          where("adId", "==", notification.adId),
          where("isDeleted", "==", false)
        );
        const notificationsSnapshot = await getDocs(notifsQuery);
        const declinePromises = notificationsSnapshot.docs.map((d) => {
          if (d.id !== notification.id) {
            return updateDoc(d.ref, { status: "declined", isDeleted: true });
          }
          return null;
        });
        await Promise.all(declinePromises);

        // Update the ad's status and tasker details
        const adSnapshot = await getDoc(adRef);
        const adData = adSnapshot.data();

        await updateDoc(adRef, {
          status: "inProgress",
          taskerName: notification.senderName || "Unknown",
          taskerUid: notification.senderUid,
          participants: [...new Set([adData.uid, notification.senderUid])],
        });

        // Fetch updated in-progress ads
        fetchInProgressAds();
        toast.success("Task-ul a fost mutat în progres!");
      } else if (action === "decline") {
        // Decline the notification and reset the ad's status to 'posted'
        await updateDoc(notificationRef, { isDeleted: true });
        await updateDoc(adRef, { status: "posted" });
        toast.success("Notificarea a fost respinsă și anunțul a fost resetat!");
      } else if (action === "finalize") {
        // Finalize the ad
        await updateDoc(adRef, { status: "finalized" });
        toast.success("Anunțul a fost marcat ca finalizat cu succes!");
      } else if (action === "notFinalize") {
        // Mark the ad as not finalized
        await updateDoc(adRef, { status: "notFinalized" });
        toast.success("Anunțul a fost marcat ca neterminat cu succes!");
      }
    } catch (error) {
      console.error("Eroare la procesarea notificării:", error);
      toast.error("Eroare la procesarea notificării.");
    }
  };

  // --------------------------------------------------
  // Completed Ads
  // --------------------------------------------------
  const fetchReviewsForAd = useCallback(async (adId) => {
    try {
      const reviewsRef = collection(firestore, "reviews");
      const qReviews = query(reviewsRef, where("adId", "==", adId));
      const snapshot = await getDocs(qReviews);
      return snapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Eroare la aducerea recenziilor:", error);
      return [];
    }
  }, []);

  const fetchCompletedAds = useCallback(async () => {
    if (!uid) return;
    try {
      const adsRef = collection(firestore, "ads");
      const qCompleted = query(
        adsRef,
        where("status", "in", ["finalized", "failed"]),
        where("participants", "array-contains", uid)
      );
      const snapshot = await getDocs(qCompleted);

      const adsWithReviews = await Promise.all(
        snapshot.docs.map(async (docSnap) => {
          const adData = { id: docSnap.id, ...docSnap.data() };
          const reviews = await fetchReviewsForAd(docSnap.id);
          return { ...adData, reviews };
        })
      );
      setCompletedAds(adsWithReviews);
    } catch (error) {
      console.error("Error fetching completed ads:", error);
    }
  }, [uid, fetchReviewsForAd]);

  useEffect(() => {
    fetchCompletedAds();
  }, [fetchCompletedAds]);

  // --------------------------------------------------
  // Helpers UI
  // --------------------------------------------------
  const getFormattedDate = (timestamp) => {
    if (!timestamp) return "Data indisponibilă";
    const date = new Date(
      timestamp.seconds ? timestamp.seconds * 1000 : timestamp
    );
    return date.toLocaleDateString("ro-RO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // Filtru
  const filteredAds = ads.filter((ad) => {
    const matchesTitle = ad.title
      .toLowerCase()
      .includes(searchTitle.toLowerCase());
    const matchesCategory = !searchCategory || ad.category === searchCategory;
    return matchesTitle && matchesCategory;
  });

  //  ============================
  //  PAYMENT LOGIC STRIPE
  //  ============================

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const sessionId = searchParams.get("session_id");

    if (status === "success" && sessionId) {
      const verifyPayment = async () => {
        try {
          const response = await fetch(
            `https://us-central1-ajutorescu-d7fab.cloudfunctions.net/paymentStatus?session_id=${sessionId}`
          );
          const result = await response.json();

          if (result.success) {
            setPaymentResult("success");
            toast.success("Plata a fost efectuată cu succes!");

            // Preia `adData` din localStorage
            const adData = JSON.parse(localStorage.getItem("adData")) || {};
            const adId = adData.adId;

            if (!adId || !adData.uid) {
              console.error(
                "Ad ID sau UID lipsește. Nu se poate salva anunțul."
              );
              toast.error(
                "A apărut o eroare: ID-ul anunțului sau UID-ul lipsește."
              );
              return;
            }

            // Creează obiectul final al anunțului
            const finalAd = {
              ...adData,
              uid: user?.uid || adData.uid,
              status: "posted",
              timestamp: Timestamp.now(),
              ownerCompleted: false,
              taskerCompleted: false,
              validity: Timestamp.fromDate(new Date(adData.validity)),
              promoted: true,
            };

            try {
              await setDoc(doc(firestore, "ads", adId), finalAd);
              toast.success("Anunțul promovat a fost salvat cu succes!");
              fetchUserAds();
            } catch (error) {
              console.error("Eroare salvare anunț promovat:", error);
              toast.error("Nu s-a putut salva anunțul promovat.");
            }
          } else {
            setPaymentResult("failure");
            toast.error("Verificarea plății a eșuat.");
          }
        } catch (error) {
          console.error("Eroare verificare plată:", error);
          toast.error("A apărut o eroare la verificarea plății.");
        } finally {
          setIsModalOpen(true);
        }
      };

      verifyPayment();
    } else if (status === "failure") {
      setPaymentResult("failure");
      setIsModalOpen(true);
    }
  }, [location.search]);

  // --------------------------------------------------
  // Return UI
  // --------------------------------------------------

  const fetchMessages = async (conversationId) => {
    if (!conversationId) return; // Nu încerca să aduci mesaje fără un ID valid

    try {
      const messagesRef = collection(
        firestore,
        `conversations/${conversationId}/messages`
      );
      const q = query(messagesRef, orderBy("timestamp", "asc"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(fetchedMessages); // Actualizează starea cu mesajele aduse
      });

      return unsubscribe;
    } catch (error) {
      console.error("Eroare la aducerea mesajelor:", error);
      toast.error("Nu s-au putut aduce mesajele.");
    }
  };

  useEffect(() => {
    let unsubscribe;

    if (selectedConversation) {
      unsubscribe = fetchMessages(selectedConversation.id);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe(); // Dezabonare pentru a preveni scurgeri de memorie
      }
    };
  }, [selectedConversation]);

  // Fetch Conversations
  const fetchConversations = useCallback(() => {
    if (!uid) return; // Ensure uid is available

    const conversationsRef = collection(firestore, "conversations");
    const q = query(
      conversationsRef,
      where("participants", "array-contains", uid),
      orderBy("updatedAt", "desc")
    );

    // Return the unsubscribe function from onSnapshot
    return onSnapshot(q, (snapshot) => {
      const fetchedConversations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setConversations(fetchedConversations);

      // Calculate unread messages
      const unreadCount = fetchedConversations.reduce((count, conv) => {
        return (
          count +
          (conv.messages?.filter((msg) => !msg.isRead && msg.sender !== uid)
            .length || 0)
        );
      }, 0);
      setUnreadMessagesCount(unreadCount);
    });
  }, [uid]);

  useEffect(() => {
    const unsubscribe = fetchConversations();
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Clean up the listener
      }
    };
  }, [fetchConversations]);

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="content-sidebar">
          <div className="image-name-section">
            <img
              src={profilePhoto}
              alt="User Profile"
              onError={(e) => (e.target.src = ProfileImage1)}
              className="profile-image"
            />
            <div className="profile-image-container">
              {profilePhoto === "default-avatar-url" ? (
                <UploadPhoto
                  uid={user?.uid}
                  onPhotoUpload={(url) => setProfilePhoto(url)}
                />
              ) : (
                <img
                  src={profilePhoto}
                  alt="User Profile"
                  onError={(e) => (e.target.src = ProfileImage1)}
                  className="profile-image"
                />
              )}
            </div>
            <h3 className="title-profile-card">{formData.displayName}</h3>
            <button onClick={handleNameEdit} className="edit-profile-btn">
              <FaEdit /> Editează profil
            </button>
          </div>
          <div className="buttons-navbar">
            <button
              onClick={() => {
                setIsNotificationModalOpen(true);
                markAllNotificationsAsRead();
              }}
              className={`notifications-button ${
                unreadCount > 0 ? "pulse-effect" : ""
              }`}>
              <FaBell /> Notificări {unreadCount > 0 && `(${unreadCount})`}
            </button>
            <button
              onClick={() => setShowFavoriteAds((prev) => !prev)}
              className={`favorite-ads-btn ${showFavoriteAds ? "active" : ""}`}>
              {showFavoriteAds ? t("favoriteAds.hide") : t("favoriteAds.show")}
            </button>
            <button
              onClick={() => setIsConversationModalOpen(true)}
              className={`conversations-button ${
                unreadMessagesCount > 0 ? "pulse-effect" : ""
              }`}>
              <FaEnvelope /> Mesaje{" "}
              {unreadMessagesCount > 0 && `(${unreadMessagesCount})`}
            </button>
            <button
              onClick={toggleInProgressAdsVisibility}
              className={`in-progress-ads-btn ${
                showInProgressAds ? "active" : ""
              }`}>
              {showInProgressAds ? "Ascunde În Curs" : "Anunțuri În Curs"}
            </button>
            <button
              onClick={() => {
                setShowCompletedAds((prev) => {
                  if (!prev) setShowInProgressAds(false);
                  return !prev;
                });
              }}
              className={`in-progress-ads-btn ${
                showCompletedAds ? "active" : ""
              }`}>
              {showCompletedAds ? "Ascunde Completate" : "Anunțuri Completate"}
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content-profile">
        {/* Modal Notificări */}
        <Modal
          isOpen={isNotificationModalOpen}
          onRequestClose={() => setIsNotificationModalOpen(false)}
          className="react-modal-content"
          overlayClassName="react-modal-overlay">
          <h2>Notificările Tale</h2>
          {notifications?.length > 0 ? (
            <>
              <ul className="notification-list">
                {notifications.map((notification) => (
                  <li key={notification.id} className="notification-item">
                    <div className="notification-content">
                      <p>{notification.message || "Notificare generică"}</p>
                      <p className="notification-date">
                        {getFormattedDate(notification.timestamp)}
                      </p>
                    </div>
                    <div className="notification-actions">
                      {notification.status === "acceptedOffer" && (
                        <>
                          <button
                            className="accept-button"
                            onClick={() =>
                              handleNotificationAction(
                                notification,
                                "finalAccept"
                              )
                            }>
                            Aprobă
                          </button>
                          <button
                            className="reject-button"
                            onClick={() =>
                              handleNotificationAction(notification, "decline")
                            }>
                            Respinge
                          </button>
                        </>
                      )}
                      {notification.status === "finalAccept" && (
                        <p>Notificare de aprobare finală</p>
                      )}
                      {!notification.status && <p>Tip notificare nedefinit</p>}
                    </div>
                  </li>
                ))}
              </ul>
              <button
                className="delete-all-button"
                onClick={async () => {
                  try {
                    const notificationRefs = notifications.map((n) =>
                      doc(firestore, "notifications", n.id)
                    );
                    await Promise.all(
                      notificationRefs.map((ref) => deleteDoc(ref))
                    );
                    setNotifications([]);
                    toast.success("Toate notificările au fost șterse!");
                  } catch (error) {
                    toast.error("Eroare la ștergerea notificărilor.");
                  }
                }}>
                Șterge toate notificările
              </button>
            </>
          ) : (
            <p>Nu ai notificări.</p>
          )}
        </Modal>

        {/* Ads Section */}
        <section id="ads" className="ads-section-profile">
          <button onClick={handleOpenAddModal} className="add-ad-btn">
            <FaPlusCircle /> Adaugă Anunț
          </button>
          {/* Modal Adăugare Anunț */}
          <Modal
            isOpen={isAddModalOpen}
            onRequestClose={() => setIsAddModalOpen(false)}
            className="modal-add-ad-container"
            overlayClassName="modal-overlay">
            <h2 className="modal-title">Adaugă Anunț</h2>
            <form className="modal-add-ad-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title" className="form-label">
                  Titlu anunț
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-input form-input-title"
                  name="title"
                  placeholder="Introdu titlul anunțului"
                  value={formData.title || ""}
                  onChange={handleChange}
                />
                <p className="char-counter">
                  {(formData.title || "").length}/150 caractere
                </p>
              </div>

              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Descriere
                </label>
                <textarea
                  id="description"
                  name="description"
                  className="form-input form-textarea"
                  placeholder={generateSuggestions(
                    formData.category || "Altele"
                  )}
                  value={formData.description || ""}
                  onChange={handleChange}
                  rows="4"></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="location" className="form-label">
                  Locație
                </label>
                <select
                  id="location"
                  name="location"
                  className="form-input form-select"
                  value={formData.location}
                  onChange={handleChange}
                  required>
                  <option value="">Selectează locația *</option>
                  {Object.entries(locationsData).map(([region, areas]) => (
                    <optgroup key={region} label={region}>
                      {areas.map((area) => (
                        <option key={area} value={area}>
                          {area}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="price" className="form-label">
                  Preț
                </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  className="form-input form-input-price"
                  placeholder="Introdu prețul"
                  value={formData.price}
                  onChange={handleChange}
                  min="1"
                  step="0.01"
                />
              </div>

              <div className="form-group">
                <label htmlFor="validity" className="form-label">
                  Data
                </label>
                <DatePicker
                  id="validity"
                  selected={
                    formData.validity
                      ? new Date(
                          formData.validity.seconds
                            ? formData.validity.seconds * 1000
                            : formData.validity
                        )
                      : null
                  }
                  onChange={(date) =>
                    setFormData((prev) => ({ ...prev, validity: date }))
                  }
                  dateFormat="dd/MM/yyyy"
                  className="form-input form-datepicker"
                  placeholderText="Selectează data"
                />
              </div>

              <div className="form-group">
                <label htmlFor="category" className="form-label">
                  Categorie
                </label>
                <select
                  id="category"
                  name="category"
                  className="form-input form-select"
                  value={formData.category || ""}
                  onChange={handleChange}>
                  <option value="">Selectează categorie *</option>
                  {categories.map((cat) => (
                    <option key={cat.name} value={cat.name}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group form-checkbox-group">
                <label className="form-checkbox-label">
                  <input
                    type="checkbox"
                    className="form-checkbox-input"
                    name="promoted"
                    checked={formData.promoted}
                    onChange={handleChange}
                  />
                  <span>Vreau să îl promovez</span>
                </label>
              </div>

              <button className="btn-submit-ad" type="submit">
                {isEditing ? "Editează Anunț" : "Adaugă Anunț"}
              </button>
            </form>
          </Modal>
          <Modal
            isOpen={isConversationModalOpen}
            onRequestClose={() => setIsConversationModalOpen(false)}
            className="react-modal-content"
            overlayClassName="react-modal-overlay">
            <h2>Conversațiile Tale</h2>
            <div className="conversations-list">
              {conversations.length > 0 ? (
                conversations.map((conv) => (
                  <div
                    key={conv.id}
                    className={`conversation-item ${
                      messages.some((msg) => !msg.isRead && msg.sender !== uid)
                        ? "unread"
                        : ""
                    }`}
                    onClick={() => setSelectedConversation(conv)}>
                    <p>
                      <strong>Cu:</strong>{" "}
                      {conv.participants.filter((p) => p !== uid).join(", ")}
                    </p>
                    <p>
                      {messages.length > 0
                        ? messages[messages.length - 1]?.text || "Niciun mesaj"
                        : "Niciun mesaj"}
                    </p>
                  </div>
                ))
              ) : (
                <p>Nu ai conversații.</p>
              )}
            </div>

            {selectedConversation && (
              <div className="conversation-messages">
                <h3>
                  Mesaje cu{" "}
                  {selectedConversation?.participants
                    .filter((p) => p !== uid)
                    .join(", ")}
                </h3>
                <div className="messages-container">
                  {messages.length > 0 ? (
                    messages.map((msg, index) => (
                      <div
                        key={index}
                        className={`message ${
                          msg.sender === uid ? "sent" : "received"
                        }`}>
                        <p>{msg.text}</p>
                        <span className="timestamp">
                          {new Date(
                            msg.timestamp?.seconds * 1000 || Date.now()
                          ).toLocaleString()}
                        </span>
                      </div>
                    ))
                  ) : (
                    <p>Niciun mesaj de afișat.</p>
                  )}
                </div>
              </div>
            )}
          </Modal>
          ;{/* Payment Result Modal */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            className="react-modal-content"
            overlayClassName="react-modal-overlay">
            {paymentResult === "success" ? (
              <div>
                <h2>Payment Successful</h2>
                <p>Your ad has been successfully promoted.</p>
              </div>
            ) : (
              <div>
                <h2>Payment Failed</h2>
                <p>There was an issue with your payment. Please try again.</p>
              </div>
            )}
            <button
              onClick={() => setIsModalOpen(false)}
              className="close-button">
              Close
            </button>
          </Modal>
          {/* Modal Editare Anunț */}
          <Modal
            isOpen={isEditModalOpen}
            onRequestClose={() => setIsEditModalOpen(false)}
            className="react-modal-content"
            overlayClassName="react-modal-overlay">
            <h2>Editează Anunț</h2>
            <form className="form-insert-ad" onSubmit={handleSubmit}>
              <input
                type="text"
                className="input-design"
                name="title"
                placeholder="Titlu"
                value={formData.title || ""}
                onChange={handleChange}
              />
              <p>{(formData.title || "").length}/150 caractere</p>

              <input
                type="number"
                name="price"
                className="input-design"
                placeholder="Preț"
                value={formData.price}
                onChange={handleChange}
                min="1"
                step="0.01"
              />
              <DatePicker
                selected={
                  formData.validity instanceof Date
                    ? formData.validity
                    : formData.validity?.seconds
                    ? new Date(formData.validity.seconds * 1000)
                    : null
                }
                onChange={(date) =>
                  setFormData((prev) => ({
                    ...prev,
                    validity: date,
                  }))
                }
                dateFormat="dd/MM/yyyy"
                className="input-design"
                placeholderText="Valabilitate"
              />

              <select
                name="category"
                value={formData.category || ""}
                onChange={handleChange}
                className="input-design">
                <option value="">Selectează categorie *</option>
                {categories.map((cat) => (
                  <option key={cat.name} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>

              <label className="promoted-label">
                <input
                  type="checkbox"
                  className="promoted-input"
                  name="promoted"
                  checked={formData.promoted}
                  onChange={handleChange}
                />
                Promovat
              </label>
              <button className="submit-ad-btn" type="submit">
                Actualizează Anunț
              </button>
            </form>
          </Modal>
          {/* IN PROGRESS ADS */}
          {showInProgressAds && (
            <section id="in-progress-ads" className="ads-section-progress">
              <h2>Anunțuri În Curs</h2>
              <div className="ads-list">
                {inProgressAds.length > 0 ? (
                  inProgressAds.map((ad) => (
                    <div
                      className={`ad-card-curs ${
                        ad.status === "inProgress"
                          ? "ad-in-progress-curs"
                          : ad.status === "completed"
                          ? "ad-completed-curs"
                          : "ad-failed-curs"
                      }`}
                      key={ad.id}>
                      <div className="ad-details">
                        <h3>{ad.title}</h3>
                        <p>Preț: {ad.price} RON</p>
                        {ad.uid === uid && (
                          <>
                            <span className="owner-badge-profile">Owner</span>
                            <p>
                              <strong>Aider:</strong>{" "}
                              {ad.taskerName || "Unknown"}
                            </p>
                            <a
                              href={`tel:${ad.taskerPhone || "#"}`}
                              className="contact-button call-button">
                              {ad.taskerPhone
                                ? "Sună Aider"
                                : "Telefon indisponibil"}
                            </a>
                          </>
                        )}
                      </div>
                      <div className="ad-actions">
                        {ad.status === "inProgress" && (
                          <>
                            {ad.uid !== uid && (
                              <>
                                <a
                                  href={`tel:${ad.ownerPhone || "#"}`}
                                  className="contact-button call-button">
                                  {ad.ownerPhone
                                    ? "Sună Seeker"
                                    : "Telefon indisponibil"}
                                </a>
                                <a
                                  href={`mailto:${ad.ownerEmail || "#"}`}
                                  className="contact-button email-button">
                                  {ad.ownerEmail
                                    ? "Email Seeker"
                                    : "Email indisponibil"}
                                </a>
                              </>
                            )}
                            <div className="buttons-finalize">
                              {(ad.uid === uid || ad.taskerUid === uid) && (
                                <button
                                  className="undone-button"
                                  onClick={() => handleOpenUndoneModal(ad)}>
                                  Task neterminat
                                </button>
                              )}
                              {ad.uid === uid && (
                                <button
                                  className="done-button"
                                  onClick={() =>
                                    handleMarkAsCompleted(ad, "owner")
                                  }>
                                  Finalizat (Seeker)
                                </button>
                              )}
                              {ad.taskerUid === uid && (
                                <button
                                  className="done-button"
                                  onClick={() =>
                                    handleMarkAsCompleted(ad, "tasker")
                                  }>
                                  Finalizat (Tasker)
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nu ai anunțuri în curs.</p>
                )}
              </div>
            </section>
          )}
          {/* Favorite ADS */}
          {showFavoriteAds && (
            <section id="favorite-ads" className="ads-section-favorites">
              <h2>Anunțurile favorite</h2>
              <div className="ads-list">
                {favoriteAds.length > 0 ? (
                  favoriteAds.map((ad) => (
                    <div className="ad-card" key={ad.id}>
                      <div className="ad-details">
                        <h3>{ad.title}</h3>
                        <p>Preț: {ad.price} RON</p>
                        <p>Categorie: {ad.category || "Nespecificată"}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nu ai anunțuri favorite.</p>
                )}
              </div>
            </section>
          )}
          {/* Completed ADS */}
          {showCompletedAds && (
            <section id="completed-ads" className="ads-section-completed">
              <h2>Anunțuri Finalizate</h2>
              <div className="ads-list">
                {completedAds.length > 0 ? (
                  completedAds.map((ad) => (
                    <div
                      key={ad.id}
                      className={`ad-card-curs ${
                        ad.status === "failed" ? "ad-failed" : "ad-finalized"
                      }`}>
                      <div className="ad-details">
                        <h3 className="ad-title">{ad.title}</h3>
                        <p className="ad-price">Preț: {ad.price} RON</p>
                        <p className="ad-category">Categorie: {ad.category}</p>
                        {ad.uid === uid && (
                          <span className="owner-badge-profile">Owner</span>
                        )}
                        {ad.status === "failed" && (
                          <p className="ad-failed-reason">
                            <strong>Motiv:</strong>{" "}
                            {ad.undoneReason || "Nespecificat"}
                          </p>
                        )}
                        <h4>Recenzii:</h4>
                        {ad.reviews?.length > 0 ? (
                          ad.reviews.map((review, index) => (
                            <div key={index} className="review-card">
                              <p className="review-author">
                                <strong>De la:</strong> {review.reviewerName}
                              </p>
                              <p className="review-for">
                                <strong>Pentru:</strong> {review.forName}
                              </p>
                              <div className="review-rating">
                                {[...Array(5)].map((_, i) => (
                                  <svg
                                    key={i}
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1.5rem"
                                    viewBox="0 0 576 512"
                                    className={
                                      i < review.rating ? "star filled" : "star"
                                    }>
                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                  </svg>
                                ))}
                              </div>
                              <p className="review-text">
                                <strong>Review:</strong> {review.review}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>Nicio recenzie.</p>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nu ai anunțuri finalizate.</p>
                )}
              </div>
            </section>
          )}
          {/* My ADS */}
          <section id="ads" className="ads-section-ads">
            <h2>Anunțurile Tale</h2>

            <div className="search-container">
              <input
                type="text"
                placeholder="Caută după titlu"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                className="input-search-profile"
              />
              <select
                value={searchCategory}
                onChange={(e) => setSearchCategory(e.target.value)}
                className="input-search-profile">
                <option value="">Toate categoriile</option>
                {categoriesData.categories?.map((cat) => (
                  <option key={cat.name} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="ads-list-profile">
              {filteredAds.length > 0 ? (
                filteredAds.map((ad) => (
                  <div className="ad-card" key={ad.id}>
                    <div className="ad-details">
                      <h3>{ad.title}</h3>
                      <p>Preț: {ad.price} RON</p>
                      <p>Categorie: {ad.category}</p>
                      {ad.uid === uid && (
                        <span className="owner-badge-profile">Owner</span>
                      )}
                    </div>
                    <div className="ad-actions">
                      <button
                        className="edit-ad-btn"
                        onClick={() => {
                          setIsEditing(true);
                          setCurrentAdId(ad.id);
                        }}>
                        <FaEdit /> Editează
                      </button>
                      <button
                        className="delete-ad-btn"
                        onClick={() => confirmDeleteAd(ad)}>
                        <FaTrashAlt /> Șterge
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>Niciun anunț găsit.</p>
              )}
            </div>
          </section>
        </section>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>Confirmare Ștergere</h2>
        <p>Ești sigur că vrei să ștergi acest anunț?</p>
        <button onClick={handleDeleteAd} className="confirm-delete-btn">
          Confirmă
        </button>
        <button onClick={closeDeleteModal} className="cancel-delete-btn">
          Anulează
        </button>
      </Modal>

      {/* Done Modal */}
      <Modal
        isOpen={isDoneModalOpen}
        onRequestClose={handleCloseModals}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>
          {currentRole === "tasker"
            ? "Review pentru Tasker"
            : "Review pentru Owner"}
        </h2>
        <div className="content-modal-done">
          <div className="rating">
            {[5, 4, 3, 2, 1].map((starValue) => (
              <label key={starValue}>
                <input
                  type="radio"
                  id={`star${starValue}`}
                  name="rate"
                  value={starValue}
                  checked={reviewData.rating === starValue}
                  onChange={() =>
                    setReviewData((prev) => ({ ...prev, rating: starValue }))
                  }
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="3rem"
                  viewBox="0 0 576 512"
                  className={reviewData.rating >= starValue ? "filled" : ""}>
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 
                    1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 
                    12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 
                    5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 
                    225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 
                    150.3 316.9 18z"
                  />
                </svg>
              </label>
            ))}
          </div>
          <textarea
            className="modern-textarea"
            placeholder="Review"
            value={reviewData.review}
            onChange={(e) =>
              setReviewData((prev) => ({ ...prev, review: e.target.value }))
            }></textarea>
        </div>
        <div className="buttons-modal-done">
          <button onClick={handleCloseModals} className="modern-close-button">
            Închide
          </button>
          <button
            onClick={() => handleSubmitReview(currentAd, currentRole)}
            className="modern-submit-button">
            Trimite
          </button>
        </div>
      </Modal>

      {/* Undone Modal */}
      <Modal
        isOpen={isUndoneModalOpen}
        onRequestClose={handleCloseModals}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>Task Nefinalizat</h2>
        <div className="content-modal-done">
          <img className="sad-emoji" src={Sad} alt="sad emoji review" />
          <textarea
            className="modern-textarea"
            placeholder="Explică motivul"
            value={undoneReason}
            onChange={(e) => setUndoneReason(e.target.value)}
            required></textarea>
        </div>
        <div className="buttons-modal-done">
          <button onClick={handleCloseModals} className="modern-close-button">
            Închide
          </button>
          <button
            onClick={() => handleSubmitUndone(currentAd)}
            className="modern-submit-button">
            Trimite
          </button>
        </div>
      </Modal>

      {/* Notification Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>Detalii Notificare</h2>
        {currentNotification && (
          <>
            <p>
              <strong>Mesaj:</strong> {currentNotification.message}
            </p>
            <p>
              <strong>Răspuns:</strong>{" "}
              {currentNotification.reply || "Niciun răspuns încă"}
            </p>
            <p>
              <strong>Data:</strong>{" "}
              {getFormattedDate(currentNotification.timestamp)}
            </p>
          </>
        )}
        <button className="close-button" onClick={() => setIsModalOpen(false)}>
          Închide
        </button>
      </Modal>

      {/* Edit Name/Email/Phone Modal */}
      {isEditingName && (
        <Modal
          isOpen={isEditingName}
          onRequestClose={() => setIsEditingName(false)}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          className="react-modal-content"
          overlayClassName="react-modal-overlay">
          <h2>Modifică informațiile de profil</h2>
          <p
            className="modal-info-text"
            style={{
              backgroundColor: "#0978d3",
              padding: "10px 15px",
              borderRadius: "8px",
              color: "#ffffff",
              fontSize: "14px",
              lineHeight: "1.5",
              marginBottom: "15px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}>
            Pentru a putea continua, avem nevoie de numărul dumneavoastră de
            telefon. Acesta va fi utilizat exclusiv pentru comunicare legată de
            anunțuri, <b> NU pentru marketing.</b>
          </p>
          <div className="edit-field">
            <input
              type="text"
              value={formData.displayName || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  displayName: e.target.value,
                }))
              }
              placeholder="Nume"
              className="input-design"
            />
          </div>
          <div className="edit-field">
            <input
              disabled
              type="email"
              value={formData.email || ""}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
              placeholder="Email"
              className="input-design"
            />
          </div>
          <div className="edit-field">
            <input
              type="text"
              value={formData.phone || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  phone: e.target.value.replace(/\D/g, ""), // Permite doar cifre
                }))
              }
              placeholder="Număr de telefon"
              className="input-design"
            />
          </div>

          <button onClick={handleConfirmSave} className="save-profile-btn">
            Salvează
          </button>
        </Modal>
      )}
    </div>
  );
};

export default Profile;
