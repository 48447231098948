import { useParams, Link } from "react-router-dom";
import articles from "../../data/articles.json";
import "../../styling/Category.css";

const Category = () => {
  const { categoryName } = useParams();
  const filteredArticles = articles.filter(
    (article) => article.category === categoryName
  );

  return (
    <div className="category-container">
      <header className="category-header">
        <h1 className="category-title">
          {categoryName.replace("-", " ").toUpperCase()}
        </h1>
        <p className="category-description">
          Descoperă articolele noastre, unde aducem inspirație, soluții practice
          și informații utile pentru a te ajuta să îți atingi obiectivele.
        </p>
      </header>

      <div className="category-articles">
        {filteredArticles.map((article, index) => (
          <Link
            to={`/blog/${categoryName}/${article.slug}`}
            key={index}
            className="article-card">
            <h3 className="article-title">{article.title}</h3>
            <p className="article-summary">
              Descoperă mai multe despre acest subiect.
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Category;
