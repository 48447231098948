import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import "../src/utils/i18n"; // Import i18n configuration

// DEBUG PROBLEMS DB
// import { setLogLevel } from "firebase/firestore";
// setLogLevel("debug");

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase only if no apps are already initialized
const app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const messaging = getMessaging(app);

// Service worker registration with module type
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js", { scope: "/" })
    .then((registration) => {

      // Check the current Notification permission
      if (Notification.permission === "granted") {
        // Permission granted, get the token
        getMessagingToken(messaging, registration);
      } else if (Notification.permission === "default") {
        // Request permission
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            getMessagingToken(messaging, registration);
          } else {
            console.warn("User denied the notification permission.");
          }
        });
      } else if (Notification.permission === "denied") {
        console.warn("Notifications are blocked.");
      }

      // Handle foreground messages
      onMessage(messaging, (payload) => {
        console.info("Message received in foreground:", payload);
        // Handle payload
      });
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

// Function to get the messaging token
function getMessagingToken(messaging, registration) {
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    serviceWorkerRegistration: registration,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.info("Current token for client:", currentToken);
        // Send the token to your server or use it as needed
      } else {
        console.error("No registration token available.");
      }
    })
    .catch((err) => {
      console.error("Error retrieving registration token:", err);
    });
}

// React rendering
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// Report performance metrics (optional)
reportWebVitals();
