// categoryHelpers.js
import categoriesData from "../data/categories.json";

/**
 * Elimină diacriticele și normalizează un string.
 */
export function removeDiacritics(text) {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

/**
 * Detectează categoria după titlu, căutând keywords în categories.json
 * @param {string} title - titlul anunțului
 * @returns {string} - numele categoriei detectate sau "Altele"
 */
export function detectCategory(title) {
  const normalizedTitle = removeDiacritics(title);
  for (const category of categoriesData.categories) {
    for (const keyword of category.keywords) {
      const regex = new RegExp(`\\b${removeDiacritics(keyword)}\\b`, "i");
      if (regex.test(normalizedTitle)) {
        return category.name;
      }
    }
  }
  return "Altele";
}

export function generateSuggestions(category) {
  const suggestions = {
    "Auto&Moto":
      "Includeți detalii despre vehicul, marca, model, kilometraj și problema tehnică întâmpinată.",
    Imobiliare:
      "Descrieți tipul proprietății, suprafața, localizarea și prețul dorit.",
    Electronice:
      "Detaliați modelul, starea, garanția, și alte caracteristici importante ale produsului.",
    "Casă și Grădină":
      "Descrieți obiectele, starea lor și orice detalii relevante despre utilitatea lor în gospodărie.",
    "Modă și Frumusețe":
      "Specificați tipul de produs, marca, starea și orice detalii relevante despre dimensiuni sau material.",
    "Mamă și Copil":
      "Includeți informații despre vârsta recomandată, starea produsului și siguranța acestuia.",
    "Sport și Hobby":
      "Menționați tipul de echipament, condiția, și cum poate fi folosit.",
    Animale:
      "Precizați specia, vârsta, starea de sănătate, și alte detalii relevante.",
    Agricole:
      "Detaliați tipul de produse agricole, starea echipamentelor sau detalii despre recolte.",
    Servicii:
      "Descrieți tipul de servicii oferite, zonele acoperite și specializări.",
    "Locuri de muncă":
      "Precizați titlul jobului, cerințele și orice beneficii asociate poziției.",
    Altele:
      "Oferiți cât mai multe detalii despre obiectul sau serviciul dorit, inclusiv cerințe specifice sau așteptări generale.",
  };

  return suggestions[category] || suggestions["Altele"];
}
