import React, { useState } from "react";
import "../styling/contact.css";

import { useTranslation } from "react-i18next"; // Import translation hook
import "@fortawesome/fontawesome-free/css/all.min.css";

const Contact = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation(); // Translation hook

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);

    // Track FAQ toggle event
    if (window.gtag) {
      window.gtag("event", "faq_toggle", {
        event_category: "FAQ",
        event_label: `Question: ${
          t("contact.faqData", { returnObjects: true })[index]?.question
        }`,
        event_action: activeIndex === index ? "Collapse" : "Expand",
      });
    }
  };


  return (
    <div id="contact" className="contact-container">
      <div className="container-contact">
        <div className="contact-info">
          <div className="title-section">
            <h2 className="title-contact">{t("contact.title")}</h2>
            <h3 className="subtitle-contact">{t("contact.subtitle")}</h3>
          </div>
          <div className="contact-lines">
            <p>
              <i className="fas fa-phone"></i>{" "}
              <a className="anchor-contact" href="tel:+40 729 126 809">
                +40 750 247 525
              </a>
            </p>
            <p>
              <i className="fas fa-envelope"></i>
              <a className="anchor-contact" href="mailto:contact@Aidly.ro">
                contact@Aidly.ro
              </a>
            </p>
            <p>
              <i className="fas fa-map-marker-alt"></i>{" "}
              <a
                className="anchor-contact"
                href="https://maps.app.goo.gl/4h6eJYamsFMhG47y6"
                target="blank"
                rel="noreferre">
                {" "}
                {t("contact.location")}
              </a>
            </p>
          </div>

          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61562032966234"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/aidly-ro"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.instagram.com/aidly.ro/"
              target="_blank"
              rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        {/* Right Side Accordion */}
        <div className="faq-section">
          <h1 className="title-faq">{t("contact.faqTitle")}</h1>
          <div className="accordion">
            {t("contact.faqData", { returnObjects: true }).map(
              (item, index) => (
                <div key={index} className="accordion-item">
                  <div
                    className="accordion-title"
                    onClick={() => toggleAccordion(index)}>
                    {item.question}
                    <span>{activeIndex === index ? "-" : "+"}</span>
                  </div>
                  <div
                    className={
                      activeIndex === index
                        ? "accordion-content show"
                        : "accordion-content"
                    }>
                    {item.answer}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
