import React, { useState } from "react";
import "../styling/modal.css";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../data/firebase";
import { toast } from "react-toastify";
import "../styling/editModal.css";
import { t } from "i18next";

const EditModal = ({ service, onClose, onSave, setServices }) => {
  const [formData, setFormData] = useState({
    title: service.title,
    description: service.description,
    price: service.price,
    location: service.location,
    category: service.category,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

const handleSave = async () => {
  try {
    const adRef = doc(firestore, "ads", service.id);
    await updateDoc(adRef, {
      title: formData.title,
      description: formData.description,
      price: Number(formData.price),
      location: formData.location,
      category: formData.category,
      // Menține câmpurile care nu sunt în formular
      ownerCompleted: service.ownerCompleted || false,
      promoted: service.promoted || false,
      status: service.status || "posted",
      taskerCompleted: service.taskerCompleted || false,
      uid: service.uid,
      validity: service.validity,
      timestamp: service.timestamp,
    });
    toast.success("Anunțul a fost actualizat cu succes!");
    setServices((prev) => {
      if (!Array.isArray(prev)) {
        console.error("Eroare: `prev` nu este un array.", prev);
        return prev; // Sau return [] pentru a evita eroarea
      }
      return prev.map((s) => (s.id === service.id ? { ...s, ...formData } : s));
    });

    onSave(formData);
    onClose();
  } catch (error) {
    console.error(
      "Eroare la actualizarea anunțului:",
      error.code,
      error.message
    );
    toast.error("Eroare la actualizarea anunțului.");
  }
};


  return (
    <div className="overlay-backdrop-edit" onClick={onClose}>
      <div className="overlay-window-edit" onClick={(e) => e.stopPropagation()}>
        <h2>{t("editModal.title")}</h2>
        <form>
          <label>
            {t("editModal.fields.title")}:
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
            />
          </label>
          <label>
            {t("editModal.fields.description")}:
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </label>
          <label>
            {t("editModal.fields.price")}:
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
            />
          </label>
          <label>
            {t("editModal.fields.location")}:
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </label>
          <label>
            {t("editModal.fields.category")}:
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
            />
          </label>
        </form>
        <div className="modal-footer">
          <button onClick={onClose} className="action-btn cancel">
            {t("editModal.actions.cancel")}
          </button>
          <button onClick={handleSave} className="action-btn save">
            {t("editModal.actions.save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
