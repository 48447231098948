import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth"; // Import pentru autentificare Firebase
import "../styling/home.css";
import { handleLogin } from "../utils/authService"; // Importă funcția de login
import HomeImage from "../assets/image.avif";
import { useTranslation } from "react-i18next";

const Home = () => {
  const navigate = useNavigate(); // Folosim useNavigate pentru redirecționare
  const auth = getAuth();
  const { t } = useTranslation();
  const currentUser = auth.currentUser; // Obținem utilizatorul curent
  const [modalContentHome, setModalContentHome] = useState(null);

  const leftCardTextsHome = t("home.leftCardTexts", { returnObjects: true });
  const rightCardTextsHome = t("home.rightCardTexts", {
    returnObjects: true,
  });

  const openModalHome = (type) => {
    if (type === "left") {
      setModalContentHome({ type: "left", content: leftCardTextsHome });
    } else if (type === "right") {
      setModalContentHome({ type: "right", content: rightCardTextsHome });
    }
  };

  const closeModalHome = () => {
    setModalContentHome(null);
  };

  // Funcție pentru scroll lin către secțiunea #services
  const scrollToServices = () => {
    const section = document.getElementById("services");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    // Track event in Google Analytics
    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: "Navigation",
        event_label: "Scroll to Services",
        value: 1,
      });
    }
  };

  // Funcție pentru a naviga către profilul utilizatorului logat sau pentru autentificare
  const goToProfile = async () => {
    if (currentUser) {
      // Dacă utilizatorul este deja autentificat, navigăm la profilul său
      navigate(`/profile/${currentUser.uid}`);
    } else {
      // Dacă utilizatorul nu este autentificat, apelăm funcția de login
      await handleLogin(navigate);
    }
    // Track event in Google Analytics
    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: "User Actions",
        event_label: "Go to Profile",
        value: 1,
      });
    }
  };

  return (
    <div id="home" className="home-container">
      <div className="left-side-home">
        <h1 className="home-title">{t("home.title")}</h1>
        <h2 className="home-subtitle">{t("home.subtitle")}</h2>
        <div className="buttons-homepage">
          <button className="btn btn--post" onClick={goToProfile}>
            {t("home.postAd")}
          </button>
          <button className="btn btn--see" onClick={scrollToServices}>
            {t("home.seeAds")}
          </button>
        </div>
        {/* <div className="student-badge">
          {t("home.studentBadge")}
          <a href="mailto:contact@Aidly.ro?subject=Ajută-mă să încep! Sunt student și vreau să fac taskuri.">
            {t("home.contactUs")}
          </a>
        </div> */}
      </div>

      {/* Slideshow în dreapta */}
      <div className="right-side-home">
        <img
          className="image-right"
          src={HomeImage}
          loading="lazy"
          alt="aidly.ro connect people"
        />
        <div className="image-overlay"></div>
      </div>
      <div
        className="test-phase-notice"
        onClick={() =>
          (window.location.href =
            "mailto:contact@Aidly.ro?subject=Am%20gasit%20un%20bug%20in%20aplicatie")
        }>
        <span className="icon">!</span>
        <span className="text">{t("home.reportBug")}</span>
      </div>

      {/* Butoane pentru modaluri */}
      <div className="info-buttons">
        <button onClick={() => openModalHome("left")}>
          {t("home.whyRequestHelp")}
        </button>
        <button onClick={() => openModalHome("right")}>
          {t("home.whyOfferHelp")}
        </button>
      </div>

      {/* Modal pentru afișarea informațiilor */}
      {modalContentHome && (
        <div className="modal-overlay-home" onClick={closeModalHome}>
          <div
            className="modal-content-home card-about"
            onClick={(e) => e.stopPropagation()}>
            <button className="close-button-home" onClick={closeModalHome}>
              &times;
            </button>
            <p className="title-card-about">
              {modalContentHome?.type === "left"
                ? t("home.whyRequestHelp")
                : t("home.whyOfferHelp")}
            </p>
            <ul className="lists">
              {modalContentHome?.content?.map((text, index) => (
                <li className="list" key={index}>
                  <svg fill="none" viewBox="0 0 24 24">
                    <path
                      fill="#000000"
                      d="M21.5821 5.54289C21.9726 5.93342 21.9726 6.56658 21.5821 6.95711L10.2526 18.2867C9.86452 18.6747 9.23627 18.6775 8.84475 18.293L2.29929 11.8644C1.90527 11.4774 1.89956 10.8443 2.28655 10.4503C2.67354 10.0562 3.30668 10.0505 3.70071 10.4375L9.53911 16.1717L20.1679 5.54289C20.5584 5.15237 21.1916 5.15237 21.5821 5.54289Z"
                      clipRule="evenodd"
                      fillRule="evenodd"></path>
                  </svg>
                  <span>{text}</span>
                </li>
              ))}
            </ul>
            {modalContentHome?.type === "left" ? (
              <button
                className="action-home"
                onClick={() => {
                  goToProfile();
                  closeModalHome();
                }}>
                {t("home.postAd")}
              </button>
            ) : (
              <button
                className="action-home"
                onClick={() => {
                  scrollToServices();
                  closeModalHome();
                }}>
                {t("home.seeAds")}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
