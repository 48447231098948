import React from "react";
import { useTranslation } from "react-i18next";
import "../styling/terms.css";

const CookiesPolicyModal = ({ showModal, closeModal }) => {
  const { t } = useTranslation();

  if (!showModal) return null;

  return (
    <div
      className={`modal-overlay-terms ${showModal ? "show" : ""}`}
      onClick={closeModal}>
      <div
        className={`modal-content-terms ${showModal ? "show" : ""}`}
        onClick={(e) => e.stopPropagation()}>
        <div className="modal-body-terms">
          <h2>{t("cookiesPolicy.title")}</h2>
          <p>{t("cookiesPolicy.intro")}</p>

          <h3>{t("cookiesPolicy.whatAreCookies.title")}</h3>
          <p>{t("cookiesPolicy.whatAreCookies.content")}</p>

          <h3>{t("cookiesPolicy.typesOfCookies.title")}</h3>
          <ul>
            <li>
              <strong>
                {t("cookiesPolicy.typesOfCookies.essential.title")}
              </strong>{" "}
              {t("cookiesPolicy.typesOfCookies.essential.content")}
            </li>
            <li>
              <strong>
                {t("cookiesPolicy.typesOfCookies.performance.title")}
              </strong>{" "}
              {t("cookiesPolicy.typesOfCookies.performance.content")}
            </li>
            <li>
              <strong>
                {t("cookiesPolicy.typesOfCookies.functional.title")}
              </strong>{" "}
              {t("cookiesPolicy.typesOfCookies.functional.content")}
            </li>
            <li>
              <strong>
                {t("cookiesPolicy.typesOfCookies.marketing.title")}
              </strong>{" "}
              {t("cookiesPolicy.typesOfCookies.marketing.content")}
            </li>
          </ul>

          <h3>{t("cookiesPolicy.manageCookies.title")}</h3>
          <p>{t("cookiesPolicy.manageCookies.content")}</p>

          <h3>{t("cookiesPolicy.contact.title")}</h3>
          <p>{t("cookiesPolicy.contact.content")}</p>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicyModal;
