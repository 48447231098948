import React, { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../data/firebase"; // Replace with your Firebase config


const UploadPhoto = ({ uid }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [photoURL, setPhotoURL] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    } else {
      console.error("No file selected.");
    }
  };

  const uploadFile = async () => {
    if (!file) {
      alert("Please select a file before uploading.");
      return;
    }
    if (!uid) {
      alert("User not logged in!");
      return;
    }

    setUploading(true);

    try {
      const storage = getStorage(); // Ensure storage is correctly initialized
      const uniqueName = `${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `user_photos/${uid}/${uniqueName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error("Error during upload:", error.code, error.message);
          setUploading(false);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setPhotoURL(downloadURL);

            const userRef = doc(firestore, "users", uid);
            await updateDoc(userRef, { photoURL: downloadURL });
          } catch (err) {
            console.error("Error saving photo URL:", err.code, err.message);
          } finally {
            setUploading(false);
          }
        }
      );
    } catch (err) {
      console.error("Upload failed:", err.message);
      alert("Upload failed: " + err.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h3>Upload Your Photo</h3>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      {uploading ? (
        <p>Uploading: {progress.toFixed(2)}%</p>
      ) : (
        <button onClick={uploadFile}>Upload</button>
      )}
      {photoURL && <img src={photoURL} alt="Uploaded Profile" width="200" />}
    </div>
  );
};

export default UploadPhoto;
