import React, { useState } from "react";
import "../styling/regularSection.css";
import { toast } from "react-toastify";
import { t } from "i18next";
import EditModal from "../components/EditModal";
import Modal from "../components/Modal"; // Ensure the Modal component is imported

const RegularSection = ({
  regularServices, // Use filtered and paginated ads from the parent
  currentPage,
  totalAds,
  servicesPerPage,
  onPageChange,
  currentUserId,
  favorites = [], // Default to an empty array
  handleFavoriteClick, // Pass the favorite click handler as a prop
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [editingService, setEditingService] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const totalPages = Math.ceil(totalAds / servicesPerPage);
  // eslint-disable-next-line
  const [services, setServices] = useState([]);

  const handleCardClick = (service) => {
    if (!currentUserId) {
      toast.error(
        "Trebuie să fii autentificat pentru a putea vedea detaliile."
      );
      return;
    }

    setSelectedService(service); // Set the service to show in the modal
    setIsDetailsModalOpen(true); // Open the modal
  };

  const categoryGradients = {
    agricultura: "#7FFF00",
    alimentatie: "#FFA07A",
    animale: "#D2B48C",
    autoandmoto: "#A9A9A9",
    casa: "#808000",
    familie: "#ADD8E6",
    curatenie: "#FFFFFF",
    design: "#2F4F4F",
    divertisment: "#FFD700",
    educatie: "#4169E1",
    electronice: "#0000FF",
    eveniment: "#8A2BE2",
    finante: "#006400",
    fotovideo: "#808080",
    frumusete: "#FFC0CB",
    gaming: "#9400D3",
    hobby: "#40E0D0",
    imobiliare: "#4682B4",
    industrie: "#B0C4DE",
    juridice: "#000080",
    marketingads: "#FF4500",
    moda: "#000000",
    reparatii: "#708090",
    socialmedia: "#ADD8E6",
    sanatate: "#98FB98",
    seniori: "#F5DEB3",
    servicii_profesionale: "#696969",
    sport: "#FF8C00",
    tehnologie: "#4682B4",
    transport: "#B22222",
    transportcargo: "#1E90FF",
    turism: "#FFD700",
  };

  const normalizeCategory = (category) =>
    category
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "") // Remove spaces
      .replace(/&/g, "and"); // Replace '&' with 'and'

  const openEditModal = (service) => {
    setEditingService(service);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditingService(null);
    setIsEditModalOpen(false);
  };

  const closeDetailsModal = () => {
    setSelectedService(null);
    setIsDetailsModalOpen(false);
  };

  const handleSave = (updatedData) => {
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === updatedData.id ? { ...service, ...updatedData } : service
      )
    );
    toast.success("Serviciul a fost actualizat.");
  };

  const formatTitle = (title) => {
    if (!title) return "";
    return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
  };

  return (
    <div className="regular-section">
      <div className="regular-container">
        {regularServices.length === 0 ? (
          <p className="no-ads-message">No ads found.</p>
        ) : (
          regularServices.map((service) => {
            const isOwner = service.uid === currentUserId;
            const isFavorite = favorites.includes(service.id);
            return (
              <div key={service.id} className="regular-card">
                <div className="card-content">
                  {isOwner && (
                    <span className="owner-badge">{t("promoted.your_ad")}</span>
                  )}
                  <div
                    className="category-tag"
                    style={{
                      background:
                        categoryGradients[
                          normalizeCategory(service.category)
                        ] || "#ccc",
                    }}>
                    {service.category}
                  </div>
                  <h3 className="promoted-card-title">
                    {formatTitle(service.title)}
                  </h3>

                  <p style={{ margin: "0", fontWeight: "light" }}>
                    {service.location}
                  </p>
                  <p className="card-price">{service.price} RON</p>
                  <div className="card-buttons">
                    {isOwner ? (
                      <button
                        className="edit-button"
                        onClick={() => openEditModal(service)}>
                        {t("promoted.edit")}
                      </button>
                    ) : (
                      <button
                        className="details-button"
                        onClick={() => handleCardClick(service)}>
                        {t("promoted.details")}
                      </button>
                    )}

                    {!isOwner && (
                      <button
                        className={`favorite-button ${
                          isFavorite ? "selected" : ""
                        }`}
                        onClick={() => handleFavoriteClick(service)}>
                        {isFavorite ? t("favorite.liked") : t("favorite.like")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {/* Details Modal */}
      {isDetailsModalOpen && selectedService && (
        <Modal
          service={selectedService}
          onClose={closeDetailsModal}
          currentUserId={currentUserId}
        />
      )}

      {isEditModalOpen && editingService && (
        <EditModal
          service={editingService}
          onClose={closeEditModal}
          onSave={handleSave}
          setServices={setServices} // Transmiterea funcției ca prop
        />
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="pagination">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}>
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => onPageChange(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}>
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default RegularSection;
