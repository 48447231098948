import React from "react";
import "../styling/terms.css";

const TermsModal = ({ showModal, closeModal }) => {
  if (!showModal) return null; // Nu afișa nimic dacă modalul este închis

  return (
    <div
      className={`modal-overlay-terms ${showModal ? "show" : ""}`}
      onClick={closeModal}>
      <div
        className={`modal-content-terms ${showModal ? "show" : ""}`}
        onClick={(e) => e.stopPropagation()}>
        <div className="modal-body-terms">
          <h2>Termeni și Condiții pentru Aidly.ro</h2>
          <p>
            <strong>Ultima actualizare:</strong> 14 ianuarie 2025
          </p>

          <h3>1. Introducere</h3>
          <p>
            Bun venit pe Aidly.ro ("Platforma", "noi", "ne" sau "nostru").
            Aidly.ro este deținută și operată de FIXMATE ORGANIC START-UP
            S.R.L.:
          </p>
          <ul>
            <li>
              <strong>Denumire companie:</strong> FIXMATE ORGANIC START-UP
              S.R.L.
            </li>
            <li>
              <strong>Adresă sediu social:</strong> București, Sector 3, Strada
              Baraj Rovinari, Nr. 12
            </li>
            <li>
              <strong>Cod Unic de Înregistrare (CUI):</strong> 51037551
            </li>
            <li>
              <strong>Număr de ordine în Registrul Comerțului:</strong>{" "}
              J2024049141001
            </li>
            <li>
              <strong>Identificator Unic la Nivel European (EUID):</strong>{" "}
              ROONRC.J2024049141001
            </li>
          </ul>

          <h3>2. Definiții</h3>
          <ul>
            <li>
              <strong>Utilizator:</strong> Orice persoană fizică sau juridică ce
              accesează sau utilizează platforma.
            </li>
            <li>
              <strong>Proprietar:</strong> Un utilizator care postează o sarcină
              sau o cerere de serviciu pe platformă.
            </li>
            <li>
              <strong>Aider:</strong> Un utilizator care oferă să finalizeze
              sarcinile postate pe platformă.
            </li>
            <li>
              <strong>Serviciu / Sarcină / Anunț:</strong> Serviciul oferit de
              platforma Aidly.ro care conectează Proprietarii cu Ajutoarele.
            </li>
          </ul>

          <h3>3. Descrierea Platformei</h3>
          <p>
            Aidly.ro este o platformă care facilitează conexiunile între
            persoane care caută ajutor (Proprietari) și cei dispuși să ofere
            astfel de ajutor (Aideri). În prezent, platforma funcționează ca un
            serviciu gratuit, fără a percepe taxe de la nicio parte implicată.
          </p>

          <h3>4. Înregistrarea Utilizatorilor și Securitatea Contului</h3>
          <p>4.1. Pentru a utiliza platforma, trebuie:</p>
          <ul>
            <li>Să aveți cel puțin 16 ani.</li>
            <li>Să vă înregistrați un cont cu informații valide.</li>
            <li>Să mențineți securitatea acreditivelor contului.</li>
            <li>
              Să ne informați prompt despre orice utilizare neautorizată a
              contului dumneavoastră.
            </li>
          </ul>

          <p>4.2. Sunteți singurul responsabil pentru:</p>
          <ul>
            <li>Toate activitățile care au loc sub contul dumneavoastră.</li>
            <li>Păstrarea confidențialității acreditivelor contului.</li>
            <li>
              Asigurarea că informațiile de înregistrare sunt corecte și
              actualizate.
            </li>
          </ul>

          <h3>5. Obligațiile Utilizatorilor</h3>
          <p>5.1. Sunteți de acord să:</p>
          <ul>
            <li>Furnizați informații corecte și adevărate.</li>
            <li>
              Utilizați platforma în conformitate cu toate legile și
              reglementările aplicabile.
            </li>
            <li>
              Respectați confidențialitatea și drepturile altor utilizatori.
            </li>
            <li>
              Comunicați profesional și respectuos cu ceilalți utilizatori.
            </li>
          </ul>

          <p>5.2. Sunteți de acord să nu:</p>
          <ul>
            <li>Utilizați platforma în scopuri ilegale.</li>
            <li>Hărțuiți, abuzați sau vătămați alți utilizatori.</li>
            <li>Postați conținut fals, înșelător sau fraudulos.</li>
            <li>
              Încercați să eludați funcțiile sau caracteristicile platformei.
            </li>
          </ul>

          <h3>6. Postarea și Realizarea Sarcinilor</h3>
          <p>6.1. Proprietarii trebuie să:</p>
          <ul>
            <li>Furnizeze descrieri clare și corecte ale sarcinilor.</li>
            <li>Specifice orice cerințe sau calificări necesare.</li>
            <li>Răspundă prompt la întrebările Ajutoarelor potențiale.</li>
          </ul>

          <p>6.2. Ajutoarele trebuie să:</p>
          <ul>
            <li>
              Oferă doar servicii pe care sunt calificate și capabile să le
              îndeplinească.
            </li>
            <li>Comunice clar despre capacitățile și limitele lor.</li>
            <li>
              Respecte angajamentele asumate pentru a finaliza sarcinile
              acceptate.
            </li>
          </ul>

          <h3>7. Plăți și Taxe</h3>
          <p>
            7.1. În prezent, Aidly.ro nu percepe taxe pentru utilizarea de bază
            a platformei. Funcții Premium, cum ar fi reclame promovate, pot fi
            taxate separat.
          </p>

          <h3>8. Răspundere și Declarații</h3>
          <p>
            Aidly.ro acționează exclusiv ca o platformă intermediară. Nu
            garantează calitatea serviciilor oferite de utilizatori și nu este
            responsabilă pentru acordurile încheiate între aceștia.
          </p>

          <h3>9. Proprietate Intelectuală</h3>
          <p>
            Conținutul și materialele Platformei, inclusiv, dar fără a se limita
            la:
          </p>
          <ul>
            <li>Logo</li>
            <li>Design</li>
            <li>Text</li>
            <li>Grafică</li>
            <li>Software</li>
          </ul>
          <p>
            sunt protejate prin drepturi de proprietate intelectuală și aparțin
            platformei Aidly.ro.
          </p>

          <h3>10. Confidențialitate și Protecția Datelor</h3>
          <p>10.1. Colectăm și procesăm datele personale conform:</p>
          <ul>
            <li>Politicii noastre de Confidențialitate</li>
            <li>
              Legislației aplicabile din România privind protecția datelor
            </li>
            <li>Regulamentului General privind Protecția Datelor (GDPR)</li>
          </ul>

          <h3>11. Soluționarea Disputelor</h3>
          <p>11.1. Utilizatorii sunt de acord să:</p>
          <ul>
            <li>
              Încerce să soluționeze disputele direct cu ceilalți utilizatori
              într-un mod organizat.
            </li>
            <li>
              Contacteze suportul Platformei pentru mediere, dacă este necesar.
            </li>
            <li>
              Se supună jurisdicției instanțelor române pentru orice proceduri
              legale.
            </li>
          </ul>

          <h3>12. Înteruperea Serviciilor</h3>
          <p>12.1. Aidly.ro își rezervă dreptul de a:</p>
          <ul>
            <li>Suspenda sau închide conturile utilizatorilor.</li>
            <li>Modifica sau închide Platforma.</li>
            <li>Elimina orice conținut la discreția sa.</li>
          </ul>

          <h3>13. Modificări ale Termenilor</h3>
          <p>
            Ne rezervăm dreptul de a modifica acești Termeni în orice moment.
            Utilizatorii vor fi notificați despre modificări prin:
          </p>
          <ul>
            <li>Anunțuri pe Platformă.</li>
            <li>Notificări pe email.</li>
            <li>Actualizări pe site-ul web.</li>
          </ul>

          <h3>14. Informații de Contact</h3>
          <p>
            Pentru întrebări legate de acești Termeni, ne puteți contacta la:
          </p>
          <ul>
            <li>Email: contact@aidly.ro</li>
            <li>Facebook: Aidly.ro</li>
            <li>Instagram: Aidly.ro</li>
          </ul>

          <h3>15. Clauza de Separabilitate</h3>
          <p>
            Dacă vreo prevedere din acești Termeni este considerată inaplicabilă
            sau invalidă:
          </p>
          <ul>
            <li>Prevederea va fi modificată în măsura minimă necesară.</li>
            <li>Celelalte prevederi vor rămâne în vigoare.</li>
          </ul>

          <h3>16. Acordul Integral</h3>
          <p>
            Acești Termeni constituie acordul integral între utilizatori și
            Aidly.ro privind utilizarea Platformei, înlocuind orice acorduri sau
            comunicări anterioare.
          </p>

          <div className="modal-footer">
            <button onClick={closeModal}>Închide</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
