import React, { useCallback, useEffect, useState } from "react";

import { Pie } from "react-chartjs-2"; // Import Pie chart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  onSnapshot,
  query,
  orderBy,
  where,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { firestore } from "../data/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../styling/admin.css";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const AdminPanel = () => {
  // eslint-disable-next-line
  const [users, setUsers] = useState([]);
  // eslint-disable-next-line
  const [ads, setAds] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [showActionModal, setShowActionModal] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [currentChatMessage, setCurrentChatMessage] = useState(null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [nameChangeRequests, setNameChangeRequests] = useState([]);
  const auth = getAuth();
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [adsList, setAdsList] = useState([]); // Lista completă de anunțuri
  const [filteredAds, setFilteredAds] = useState([]); // Lista de anunțuri filtrată
  const [searchTerm, setSearchTerm] = useState(""); // Termenul de căutare
  const [searchTermUsers, setSearchTermUsers] = useState(""); // Termen de căutare pentru utilizatori
  const [sortOrder, setSortOrder] = useState("asc"); // Stare pentru ordinea de sortare
  const [statistics, setStatistics] = useState({
    totalUsers: 0,
    totalAds: 0,
    completedAds: 0,
    activeAds: 0,
  });

  const fetchAllAds = useCallback(async () => {
    try {
      // Preia toate anunțurile din Firestore
      const adsCollection = collection(firestore, "ads");
      const adsSnapshot = await getDocs(adsCollection);
      const adsData = adsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Preia toate datele utilizatorilor din Firestore
      const usersCollection = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersMap = usersSnapshot.docs.reduce((acc, userDoc) => {
        acc[userDoc.id] = userDoc.data(); // Creează o mapare userId -> date utilizator
        return acc;
      }, {});

      // Adaugă informații suplimentare la fiecare anunț
      const enrichedAds = adsData.map((ad) => {
        const user = usersMap[ad.uid] || {}; // Găsește utilizatorul pe baza `uid`
        return {
          ...ad,
          displayName: user.displayName || "Unknown User",
          city: ad.location || "Unknown City", // Utilizează `location` din anunț
          phoneNumber: user.phoneNumber || "N/A",
        };
      });

      setAdsList(enrichedAds); // Setează lista completă de anunțuri
      setFilteredAds(enrichedAds); // Setează lista filtrată
    } catch (error) {
      console.error("Error fetching ads with user data:", error);
      toast.error("Eroare la preluarea datelor anunțurilor.");
    }
  }, []);

  const handleSortByAds = () => {
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.totalAds - b.totalAds;
      } else {
        return b.totalAds - a.totalAds;
      }
    });

    setFilteredUsers(sortedUsers);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Inversează ordinea sortării
  };

  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, ""); // Elimină diacriticele
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === "") {
      setFilteredAds(adsList);
    } else {
      const normalizedTerm = normalizeText(term);

      const filtered = adsList.filter((ad) => {
        const fieldsToSearch = [
          ad.title,
          ad.displayName,
          ad.city,
          ad.phoneNumber,
        ];

        return fieldsToSearch.some((field) =>
          field ? normalizeText(field).includes(normalizedTerm) : false
        );
      });

      setFilteredAds(filtered);
    }
  };

  const handleUserSearch = (e) => {
    const term = e.target.value;
    setSearchTermUsers(term);

    if (term === "") {
      setFilteredUsers(users); // Revine la lista completă dacă nu e niciun termen
    } else {
      const normalizedTerm = normalizeText(term);

      const filtered = users.filter((user) => {
        const fieldsToSearch = [user.displayName, user.email, user.phoneNumber];

        return fieldsToSearch.some((field) =>
          field ? normalizeText(field).includes(normalizedTerm) : false
        );
      });

      setFilteredUsers(filtered);
    }
  };

  // Fetch all users
  const fetchUsers = useCallback(async () => {
    try {
      // Fetch utilizatori
      const usersCollection = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersCollection);

      // Fetch anunțuri
      const adsCollection = collection(firestore, "ads");
      const adsSnapshot = await getDocs(adsCollection);

      // Construim un obiect de contorizare pe baza câmpului "uid"
      const userAdCounts = adsSnapshot.docs.reduce((acc, adDoc) => {
        const adData = adDoc.data();
        const userId = adData.uid; // Asigurăm utilizarea câmpului corect, `uid`

        if (userId) {
          acc[userId] = (acc[userId] || 0) + 1;
        }
        return acc;
      }, {});

      // Mapăm lista de utilizatori și adăugăm `totalAds` pentru fiecare
      const userList = usersSnapshot.docs.map((userDoc) => {
        const userId = userDoc.id;
        const userData = userDoc.data();
        const totalAds = userAdCounts[userId] || 0; // Numărul de anunțuri sau 0

        return {
          id: userId,
          ...userData,
          totalAds,
        };
      });

      setUsers(userList);
      setFilteredUsers(userList);
    } catch (error) {
      console.error("Error fetching users and ads:", error);
      toast.error("Eroare la preluarea datelor utilizator și anunț.");
    }
  }, []);

  // Fetch terminated or deleted ads
  const fetchAds = useCallback(async () => {
    const adsCollection = collection(firestore, "ads");
    const adsQuery = query(adsCollection, where("isDeleted", "==", true));
    const adsSnapshot = await getDocs(adsQuery);
    const adList = adsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAds(adList);
    setFilteredAds(adList);
  }, []);

  // const restoreAd = async (adId, userId) => {
  //   if (!adId) {
  //     toast.error("Invalid ad ID.");
  //     return;
  //   }

  //   try {
  //     // Verify the ad exists
  //     const adRef = doc(firestore, "ads", adId);
  //     const adDoc = await getDoc(adRef);

  //     if (!adDoc.exists()) {
  //       toast.error("Ad not found.");
  //       return;
  //     }

  //     // Update the ad to restore it
  //     await updateDoc(adRef, {
  //       isDeleted: false,
  //       deletedAt: null,
  //       deletedBy: null,
  //     });

  //     // Send a notification only if userId is valid
  //     if (userId) {
  //       await addDoc(collection(firestore, "notifications"), {
  //         userId: userId,
  //         message: `Your ad has been restored.`,
  //         timestamp: new Date(),
  //         isRead: false,
  //       });
  //     }

  //     toast.success("Ad successfully restored!");
  //     setFilteredAds((prevAds) =>
  //       prevAds.map((ad) =>
  //         ad.id === adId ? { ...ad, isDeleted: false, deletedAt: null } : ad
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error restoring ad:", error);
  //     toast.error("Error restoring ad.");
  //   }
  // };

  // Fetch chatbot messages
  const fetchConversations = useCallback(() => {
    try {
      // Query Firestore users and create a mapping of userId to displayName
      const q = query(
        collection(firestore, "conversations"),
        orderBy("updatedAt", "desc")
      );

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        // Build userId-to-displayName map from the Firestore `users` collection
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        const usersMap = usersSnapshot.docs.reduce((acc, userDoc) => {
          acc[userDoc.id] = userDoc.data().displayName || "Unknown User";
          return acc;
        }, {});

        const convs = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            userName: usersMap[data.userId] || "Unknown User",
          };
        });

        setConversations(convs);

        // Calculate unread messages
        const unreadCount = convs.reduce((count, conv) => {
          return (
            count + (conv.messages?.filter((msg) => !msg.isRead).length || 0)
          );
        }, 0);
        setUnreadMessagesCount(unreadCount);
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching conversations:", error);
      toast.error("Failed to fetch conversations.");
      return () => {}; // Return a no-op function on error
    }
  }, []);

  useEffect(() => {
    return () => {
      if (
        currentChatMessage?.unsubscribe &&
        typeof currentChatMessage.unsubscribe === "function"
      ) {
        currentChatMessage.unsubscribe(); // Call unsubscribe for the specific conversation
      }
    };
  }, [currentChatMessage]);

  useEffect(() => {
    const unsubscribe = fetchConversations();
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [fetchConversations]);

  const confirmDeleteAd = (adId) => {
    setAdToDelete(adId);
    setShowDeleteConfirm(true);
  };

  // Define the additional actions for conversations
  const handleAdditionalAction = async (action, conv) => {
    try {
      const convRef = doc(firestore, "conversations", conv.id);

      switch (action) {
        case "delete":
          await deleteDoc(convRef);
          toast.success("Conversation deleted successfully!");
          setConversations((prev) => prev.filter((c) => c.id !== conv.id));
          break;

        case "pin":
          // Update Firestore to mark the conversation as pinned
          await updateDoc(convRef, { isPinned: true });
          toast.success("Conversation pinned to top!");
          // Move the pinned conversation to the top of the list
          setConversations((prev) => {
            const updatedConvs = prev.map((c) =>
              c.id === conv.id ? { ...c, isPinned: true } : c
            );
            return updatedConvs.sort((a, b) => b.isPinned - a.isPinned);
          });
          break;

        case "resolve":
          // Mark the conversation as resolved
          await updateDoc(convRef, { status: "resolved" });
          toast.success("Conversation marked as resolved!");
          setConversations((prev) =>
            prev.map((c) =>
              c.id === conv.id ? { ...c, status: "resolved" } : c
            )
          );
          break;

        default:
          toast.error("Unknown action!");
      }
    } catch (error) {
      console.error("Error handling action:", error);
      toast.error("Failed to perform action. Please try again.");
    }
  };

  const openActionModal = (conversation) => {
    setSelectedConversation(conversation);
    setShowActionModal(true);
  };

  const closeActionModal = () => {
    setSelectedConversation(null);
    setShowActionModal(false);
  };

  useEffect(() => {
    const unsubscribe = fetchConversations();
    return () => {
      unsubscribe(); // Cleanup the Firestore listener
    };
  }, [fetchConversations]);

  const handleConfirmDelete = async () => {
    try {
      await deleteDoc(doc(firestore, "ads", adToDelete));
      toast.success("Ad deleted successfully!");
      setFilteredAds(filteredAds.filter((ad) => ad.id !== adToDelete));
    } catch (error) {
      toast.error("Error deleting ad.");
    } finally {
      setShowDeleteConfirm(false);
      setAdToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
    setAdToDelete(null);
  };

  // Respond to chatbot message
  const sendReply = async () => {
    if (!replyMessage.trim() || !currentChatMessage) return;

    try {
      const adminUser = auth.currentUser;
      const adminDisplayName = adminUser?.displayName || "Admin";

      await addDoc(
        collection(
          firestore,
          `conversations/${currentChatMessage.id}/messages`
        ),
        {
          sender: "admin",
          text: `AidlySupport: ${replyMessage}`,
          timestamp: new Date(),
        }
      );

      // Optionally update conversation metadata
      await updateDoc(doc(firestore, "conversations", currentChatMessage.id), {
        updatedAt: new Date(),
      });

      setReplyMessage("");
    } catch (error) {
      toast.error("Error sending reply.");
      console.error("Send reply error:", error);
    }
  };

  const viewConversation = (conv) => {
    try {
      const messagesQuery = query(
        collection(firestore, `conversations/${conv.id}/messages`),
        orderBy("timestamp", "asc")
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCurrentChatMessage({
          ...conv,
          messages,
          unsubscribe, // Save the unsubscribe function
        });
      });

      return unsubscribe; // Return unsubscribe for cleanup
    } catch (error) {
      console.error("Error fetching conversation messages:", error);
      toast.error("Failed to load conversation messages.");
      return () => {}; // Return a no-op function on error
    }
  };

  // Delete chatbot message
  const deleteConversation = async (convId) => {
    try {
      // Confirm before deleting
      confirmAlert({
        title: "Confirm Delete",
        message: "Are you sure you want to delete this conversation?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const conversationRef = doc(firestore, "conversations", convId);
              const messagesRef = collection(
                firestore,
                `conversations/${convId}/messages`
              );

              // First, delete all messages in the subcollection
              const messagesSnapshot = await getDocs(messagesRef);
              const deleteMessagePromises = messagesSnapshot.docs.map((doc) =>
                deleteDoc(doc.ref)
              );
              await Promise.all(deleteMessagePromises);

              // Then, delete the conversation document
              await deleteDoc(conversationRef);

              // Update UI state
              setConversations((prev) =>
                prev.filter((conv) => conv.id !== convId)
              );
              toast.success("Conversation deleted successfully!");
            },
          },
          {
            label: "No",
          },
        ],
      });
    } catch (error) {
      console.error("Error deleting conversation:", error);
      toast.error("Failed to delete the conversation.");
    }
  };

  // Activează utilizatorul
  const handleActivateUser = async (userId) => {
    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, { isActive: true });
      toast.success("Utilizator activat!");
      closeModal();
    } catch (error) {
      toast.error("Eroare la activarea utilizatorului.");
    }
  };

  // Dezactivează utilizatorul
  const handleDeactivateUser = async (userId) => {
    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, { isActive: false });
      toast.success("Utilizator dezactivat!");
      closeModal();
    } catch (error) {
      toast.error("Eroare la dezactivarea utilizatorului.");
    }
  };

  // Șterge utilizatorul
  const handleDeleteUser = async (userId) => {
    try {
      await deleteDoc(doc(firestore, "users", userId));
      toast.success("Utilizator șters!");
      closeModal();
    } catch (error) {
      toast.error("Eroare la ștergerea utilizatorului.");
    }
  };

  // Resetează parola utilizatorului (folosind Firebase Authentication)
  const handleResetPassword = async (userId) => {
    try {
      const auth = getAuth();
      const userRef = doc(firestore, "users", userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const email = userDoc.data().email;
        await sendPasswordResetEmail(auth, email);
        toast.success("Email pentru resetare parolă trimis!");
        closeModal();
      } else {
        toast.error("Utilizatorul nu există.");
      }
    } catch (error) {
      toast.error("Eroare la trimiterea email-ului de resetare parolă.");
    }
  };

  const handleManageUser = (user) => {
    setSelectedUser(user);
    setShowUserModal(true);
    document.body.style.overflow = "hidden"; // Dezactivează scroll-ul
  };

  const closeModal = () => {
    setShowUserModal(false);
    setSelectedUser(null);
    document.body.style.overflow = "auto"; // Reactivază scroll-ul
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchUsers();
    fetchAds();
    fetchAllAds(); // Fetch all ads
  }, [fetchUsers, fetchAds, fetchAllAds]);

  // STATISTICI

  const pieData = {
    labels: ["Completed Ads", "Active Ads", "Other Ads"],
    datasets: [
      {
        label: "# of Ads",
        data: [
          statistics.completedAds,
          statistics.activeAds,
          statistics.totalAds - statistics.completedAds - statistics.activeAds,
        ],
        backgroundColor: ["#4ff800", "#ffa600", "#FFCE56"],
        borderWidth: 1,
      },
    ],
  };

  const fetchStatistics = useCallback(async () => {
    try {
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      const adsSnapshot = await getDocs(collection(firestore, "ads"));

      const totalUsers = usersSnapshot.docs.length;
      const totalAds = adsSnapshot.docs.length;
      const completedAds = adsSnapshot.docs.filter(
        (doc) => doc.data().status === "finalized"
      ).length;
      const activeAds = adsSnapshot.docs.filter(
        (doc) =>
          doc.data().status === "posted" || doc.data().status === "inProgress"
      ).length;

      setStatistics({ totalUsers, totalAds, completedAds, activeAds });
    } catch (error) {
      console.error("Error fetching statistics:", error);
      toast.error("Eroare la preluarea statisticilor.");
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      await fetchStatistics();
    };
    loadData();
  }, [fetchStatistics]);

  useEffect(() => {
    return () => {
      if (currentChatMessage?.unsubscribe) {
        currentChatMessage.unsubscribe();
      }
    };
  }, [currentChatMessage]);

  return (
    <div className="admin-panel">
      <ToastContainer position="bottom-left" />
      <h1>Admin Dashboard</h1>

      <div className="admin-panel-container">
        {/* Users Section */}
        <section className="total-users section-admin-panel">
          <h2>All Users</h2>
          <input
            type="text"
            placeholder="Search by name, email, or phone"
            value={searchTermUsers}
            onChange={handleUserSearch}
            className="search-input"
          />
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th onClick={handleSortByAds} style={{ cursor: "pointer" }}>
                  Total Ads {sortOrder === "asc" ? "⬆️" : "⬇️"}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.displayName || "Unknown"}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber || "N/A"}</td>
                  <td>{user.totalAds || 0}</td>
                  <td>
                    <button
                      className="manage-users"
                      onClick={() => handleManageUser(user)}>
                      Gestionare
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className="all-ads section-admin-panel">
          <h2>All Ads</h2>
          <input
            type="text"
            placeholder="Search by title, user name, city, or phone"
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>User Name</th>
                <th>City</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredAds.map((ad) => (
                <tr key={ad.id}>
                  <td>{ad.title}</td>
                  <td>{ad.displayName || "Unknown"}</td>
                  <td>{ad.city || "Unknown"}</td>
                  <td>{ad.phoneNumber || "N/A"}</td>
                  <td>
                    <button
                      onClick={() => confirmDeleteAd(ad.id)}
                      className="button-ad-delete-admin">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        {/* Ads Section */}
        {/* <section className="total-ads section-admin-panel">
          <h2>Deleted Ads</h2>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Posted by</th>
                <th>Deleted By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredAds.map((ad) => (
                <tr key={ad.id}>
                  <td>{ad.title}</td>
                  <td>{ad.displayName || "Unknown"}</td>
                  <td>{ad.deletedBy || "Unknown"}</td>
                  <td>
                    <button onClick={() => restoreAd(ad.id, ad.userId)}>
                      Restore
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section> */}

        <section className="conversations-section section-admin-panel">
          <h2>Conversations ({unreadMessagesCount} unread)</h2>
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Last Message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {conversations.length > 0 ? (
                conversations.map((conv) => {
                  const lastMessage =
                    conv.messages?.[conv.messages.length - 1]?.text ||
                    "No messages";
                  return (
                    <tr key={conv.id}>
                      <td>{conv.userName || "Unknown"}</td>
                      <td>{lastMessage}</td>
                      <td>
                        <button
                          className="view-button"
                          onClick={() => viewConversation(conv)}>
                          View
                        </button>

                        <button
                          className="delete-button"
                          onClick={() => deleteConversation(conv.id)}>
                          Delete
                        </button>
                        <button
                          className="additional-action-button"
                          onClick={() => openActionModal(conv)}>
                          Additional Action
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">No conversations available</td>
                </tr>
              )}
            </tbody>
          </table>
        </section>

        <section className="statistics-section section-admin-panel">
          <h2 className="statistics-title">Platform Statistics</h2>
          <div className="statistics-cards">
            <div className="statistics-card">
              <h3>Total Users</h3>
              <p>{statistics.totalUsers}</p>
            </div>
            <div className="statistics-card">
              <h3>Total Ads</h3>
              <p>{statistics.totalAds}</p>
            </div>
            <div className="statistics-card">
              <h3>Completed Ads</h3>
              <p>{statistics.completedAds}</p>
            </div>
            <div className="statistics-card">
              <h3>Active Ads</h3>
              <p>{statistics.activeAds}</p>
            </div>
          </div>
          <div className="chart-container">
            <h3>Ad Distribution</h3>
            <Pie data={pieData} />
          </div>
        </section>
      </div>

      {showDeleteConfirm && (
        <div className="modal-overlay-delete" onClick={handleCancelDelete}>
          <div
            className="modal-content-delete"
            onClick={(e) => e.stopPropagation()} // Prevent closing on modal content click
          >
            <h2>Confirm Delete</h2>
            <p>
              Are you sure you want to delete this ad? This action cannot be
              undone.
            </p>
            <div className="modal-buttons">
              <button
                className="modal-button confirm"
                onClick={handleConfirmDelete}>
                Yes, Delete
              </button>
              <button
                className="modal-button cancel"
                onClick={handleCancelDelete}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showUserModal && (
        <div className="modal-overlay-manage-users" onClick={closeModal}>
          <div
            className="modal-content-manage-users"
            onClick={(e) => e.stopPropagation()} // Prevent modal close on click inside
          >
            <button
              className="close-modal-button-manage-users"
              onClick={closeModal}>
              &times;
            </button>
            <h2 className="modal-title">Gestionare Utilizator</h2>
            <div className="modal-user-details">
              <p>
                <strong>Nume:</strong> {selectedUser?.displayName || "Unknown"}
              </p>
              <p>
                <strong>Email:</strong> {selectedUser?.email}
              </p>
              <p>
                <strong>Telefon:</strong> {selectedUser?.phoneNumber || "N/A"}
              </p>
            </div>
            <div className="modal-actions-manage-users">
              <button
                className="modal-button activate"
                onClick={() => handleActivateUser(selectedUser.id)}>
                Activează
              </button>
              <button
                className="modal-button deactivate"
                onClick={() => handleDeactivateUser(selectedUser.id)}>
                Dezactivează
              </button>
              <button
                className="modal-button delete"
                onClick={() => handleDeleteUser(selectedUser.id)}>
                Șterge
              </button>
              <button
                className="modal-button reset-password"
                onClick={() => handleResetPassword(selectedUser.id)}>
                Resetează Parola
              </button>
            </div>
          </div>
        </div>
      )}

      {showActionModal && selectedConversation && (
        <div className="conversation-modal-overlay" onClick={closeActionModal}>
          <div
            className="conversation-modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent modal close on click inside
          >
            <h2>Manage Conversation</h2>
            <p>
              What action would you like to take for{" "}
              <strong>{selectedConversation.userId || "Unknown User"}</strong>?
            </p>
            <div className="conversation-modal-buttons">
              <button
                className="conversation-modal-button pin"
                onClick={() =>
                  handleAdditionalAction("pin", selectedConversation)
                }>
                Pin to Top
              </button>
              <button
                className="conversation-modal-button resolve"
                onClick={() =>
                  handleAdditionalAction("resolve", selectedConversation)
                }>
                Mark as Resolved
              </button>
              <button
                className="conversation-modal-button delete"
                onClick={() =>
                  handleAdditionalAction("delete", selectedConversation)
                }>
                Delete
              </button>
              <button
                className="conversation-modal-button cancel"
                onClick={closeActionModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {currentChatMessage && (
        <div className="modal-overlay-chatbot">
          <div className="modal-content-center">
            <div className="modal-header-chatbot">
              <h3>
                Conversation with{" "}
                {currentChatMessage.userName || "Unknown User"}
              </h3>
              <button
                onClick={() => {
                  setCurrentChatMessage(null);
                }}>
                &times;
              </button>
            </div>
            <div className="modal-body-chatbot">
              <div className="messages-container">
                {currentChatMessage.messages?.map((msg, index) => (
                  <div
                    key={index}
                    className={`message ${
                      msg.sender === "user" ? "user" : "admin"
                    }`}>
                    <p>{msg.text}</p>
                    <span className="timestamp">
                      {new Date(
                        msg.timestamp?.seconds * 1000 || Date.now()
                      ).toLocaleString()}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer-chatbot">
              <textarea
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
                placeholder="Type your reply..."
                rows="2"
              />
              <button onClick={sendReply}>Send</button>
            </div>
          </div>
        </div>
      )}

      {showReplyModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Reply to Message</h2>
            <textarea
              value={replyMessage}
              onChange={(e) => setReplyMessage(e.target.value)}
            />
            <div className="modal-buttons">
              <button onClick={sendReply}>Send Reply</button>
              <button onClick={() => setShowReplyModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
