import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../data/firebase";
import "../styling/userProfile.css";

const UserProfile = () => {
  const { uid } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [userAds, setUserAds] = useState([]);
  const [finalizedAds, setFinalizedAds] = useState([]); // Anunțuri finalizate
  const [averageRating, setAverageRating] = useState(0);


  // Fetch user information
  const fetchUserInfo = async () => {
    if (!uid) {
      console.warn("UID is missing. Cannot fetch user info.");
      return;
    }

    try {
      const userRef = doc(firestore, "users", uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setUserInfo(userSnap.data());
      } else {
        console.warn("User document not found.");
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const fetchUserAds = async () => {
    if (!uid) {
      console.warn("UID is missing. Cannot fetch user ads.");
      return;
    }

    try {
      const adsQuery = query(
        collection(firestore, "ads"),
        where("uid", "==", uid)
      );
      const adsSnapshot = await getDocs(adsQuery);

      if (!adsSnapshot.empty) {
        const ads = adsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Separate finalized ads
        const finalized = ads.filter((ad) => ad.status === "finalized");
        const otherAds = ads.filter((ad) => ad.status !== "finalized");

        setUserAds(otherAds);
        setFinalizedAds(finalized);
      } else {
        console.warn("No ads found for this user.");
      }
    } catch (error) {
      console.error("Error fetching user ads:", error);
    }
  };

  useEffect(() => {
    if (uid) {
      fetchUserInfo();
      fetchUserAds();
      calculateAverageRating();
    }
    // eslint-disable-next-line
  }, [uid]);

  // Calculate average rating
  const calculateAverageRating = async () => {
    try {
      const reviewsQuery = query(
        collection(firestore, "reviews"),
        where("forUid", "==", uid) // Schimbat din "userId" în "forUid"
      );
      const reviewsSnapshot = await getDocs(reviewsQuery);

      if (!reviewsSnapshot.empty) {
        const ratings = reviewsSnapshot.docs.map(
          (doc) => doc.data().rating || 0
        );
        const avg =
          ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length; // Calcul corect al mediei
        setAverageRating(avg.toFixed(1)); // Rotunjire la o zecimală
      } else {
        console.warn("No reviews found for this user.");
        setAverageRating(0); // Fără recenzii, rating-ul este 0
      }
    } catch (error) {
      console.error("Eroare la calcularea rating-ului utilizatorului:", error);
    }
  };

  useEffect(() => {
    if (uid) {
      fetchUserInfo();
      fetchUserAds();
      calculateAverageRating();
    } else {
      console.warn("UID is missing, cannot fetch data.");
    }
    // eslint-disable-next-line
  }, [uid]);

  // Generate mailto link for reporting
  const reportUserLink = `mailto:contact@aidly.ro?subject=Raportează Utilizator (UID: ${uid})&body=${encodeURIComponent(
    `Bună ziua,

Doresc să raportez utilizatorul cu numele: "${
      userInfo?.displayName || "Anonim"
    }" și UID-ul: "${uid}" pentru următoarele motive:

(Descrieți motivul aici).`
  )}`;

  return (
    <div className="user-profile-page">
      {/* User Info Section */}
      <div className="user-info">
        {userInfo ? (
          <>
            <img
              src={userInfo.photoURL || "/default-avatar.png"}
              alt={`${userInfo.displayName || "Anonim"}'s avatar`}
              className="user-avatar"
            />
            <div className="info-name-userprofile">
              <h2 className="user-name">{userInfo.displayName || "Anonim"}</h2>

              <div className="user-rating">
                <strong>Rating: </strong> {averageRating} / 5{" "}
                {"★".repeat(Math.round(averageRating))}{" "}
                {"☆".repeat(5 - Math.round(averageRating))}
              </div>

              <a
                href={reportUserLink}
                className="report-user-btn"
                style={{
                  display: "inline-block",
                  marginTop: "10px",
                  padding: "10px 20px",
                  backgroundColor: "#e74c3c",
                  color: "white",
                  textDecoration: "none",
                  borderRadius: "5px",
                }}>
                Raportează Utilizator
              </a>
            </div>
          </>
        ) : (
          <p>Se încarcă informațiile utilizatorului...</p>
        )}
      </div>

      {/* User Ads Section */}
      <div className="user-ads">
        <h3>Anunțurile utilizatorului</h3>
        {userAds.length > 0 ? (
          <div className="ads-list-user">
            {userAds.map((ad) => (
              <div key={ad.id} className="ad-card">
                <h4 className="ad-title">{ad.title}</h4>
                <p className="ad-price">
                  <strong>Preț:</strong> {ad.price} RON
                </p>
                <p className="ad-category">
                  <strong>Categorie:</strong> {ad.category}
                </p>
                <p className="ad-description">{ad.description}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Nu există anunțuri active postate de acest utilizator.</p>
        )}
      </div>

      {/* Finalized Ads Section */}
      <div className="user-finalized-ads">
        <h3>Anunțurile Finalizate</h3>
        {finalizedAds.length > 0 ? (
          <div className="ads-list-finalized">
            {finalizedAds.map((ad) => (
              <div key={ad.id} className="ad-card finalized-ad">
                <h4 className="ad-title">{ad.title}</h4>
                <p className="ad-price">
                  <strong>Preț:</strong> {ad.price} RON
                </p>
                <p className="ad-category">
                  <strong>Categorie:</strong> {ad.category}
                </p>
                <p className="ad-description">{ad.description}</p>
                <span className="ad-badge">Finalizat</span>
              </div>
            ))}
          </div>
        ) : (
          <p>Nu există anunțuri finalizate postate de acest utilizator.</p>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
