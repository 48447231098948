import React, { useEffect, useState } from "react";
import "../styling/modal.css";
import { firestore } from "../data/firebase";
import emailjs from "@emailjs/browser";

import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  Timestamp,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

// Helper Function: Create Notification
const createNotification = async ({
  recipientUid,
  senderUid,
  adId,
  message,
  status,
}) => {
  if (!recipientUid || !adId || !status || !message) {
    console.error("Missing required notification fields.");
    return;
  }

  try {
    await addDoc(collection(firestore, "notifications"), {
      recipientUid,
      senderUid,
      adId,
      message,
      status,
      timestamp: Timestamp.now(),
      isRead: false,
      isDeleted: false,
    });
  } catch (error) {
    console.error("Error creating notification:", error);
  }
};

const Modal = ({ service, onClose, currentUserId }) => {
  const [ownerName, setOwnerName] = useState("Anonim");
  const navigate = useNavigate();
  const [ownerEmail, setOwnerEmail] = useState(null);

  const googleMapLink = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAD8Tz9Ns6ipEIKk1eaI0RM1PRJUuiwnxA&q=${encodeURIComponent(
    service.location
  )}`;

  const fetchOwnerDetails = async () => {
    if (!service.uid) return;

    try {
      const userDocRef = doc(firestore, "users", service.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setOwnerName(userData.displayName || "Anonim");
        setOwnerEmail(userData.email || null); // Store the owner's email
      } else {
        console.warn("Proprietarul anunțului nu a fost găsit în baza de date.");
      }
    } catch (error) {
      console.error("Eroare la obținerea detaliilor proprietarului:", error);
    }
  };

  useEffect(() => {
    fetchOwnerDetails();
    // eslint-disable-next-line
  }, [service.uid]);

  const handleAccept = async () => {
    if (!service || !service.id) {
      console.error("Service or service ID is undefined.");
      toast.error("Anunțul nu mai există sau are date incomplete.");
      return;
    }

    if (service.status === "inProgress") {
      console.warn("Anunțul a fost deja acceptat și este în curs.");
      toast.error("Anunțul a fost deja acceptat și este în curs.");
      return;
    }

    if (
      window.confirm(
        `Ești sigur că vrei să accepți această ofertă pentru "${service.title}"?`
      )
    ) {
      try {
        // Fetch task data
        const adRef = doc(firestore, "ads", service.id);
        const adSnap = await getDoc(adRef);

        if (!adSnap.exists()) {
          console.warn(`Anunțul "${service.title}" nu mai există.`);
          toast.error(`Anunțul "${service.title}" nu mai există.`);
          return;
        }

        const adData = adSnap.data();

        // Prevent further acceptance if already in progress
        if (adData.status === "inProgress") {
          console.warn("Anunțul a fost deja acceptat și este în curs.");
          toast.error("Anunțul a fost deja acceptat și este în curs.");
          return;
        }

        // Fetch tasker details (current user)
        const userRef = doc(firestore, "users", currentUserId);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
          console.error("Utilizatorul curent nu există.");
          toast.error("Utilizatorul curent nu există.");
          return;
        }

        const taskerData = userSnap.data();

        if (!taskerData.displayName) {
          console.error("Tasker name is missing.");
        }
        if (!taskerData.phoneNumber) {
          console.error("Tasker phone number is missing.");
        }
        if (!service.id) {
          console.error("Service ID is missing.");
        }

        try {
          await updateDoc(adRef, {
            status: "accepted",
            taskerName: taskerData.displayName || "Anonim",
            taskerPhone: taskerData.phoneNumber || "Necunoscut",
          });
        } catch (error) {
          console.error("Error updating document:", error);
          toast.error("Eroare la actualizarea documentului.");
        }

        // Create a notification for the task owner
        await createNotification({
          recipientUid: service.uid, // Owner of the task
          senderUid: currentUserId, // Tasker who accepted the task
          adId: service.id,
          message: `Oferta pentru anunțul "${service.title}" a fost acceptată.`,
          status: "acceptedOffer",
        });
        // Send an email notification to the owner
        const emailTemplateParams = {
          to_email: ownerEmail, // Use the owner's email retrieved from Firestore // Email-ul proprietarului
          owner_name: ownerName || "Owner", // Numele proprietarului
          task_title: service.title, // Titlul anunțului
          accepted_by: taskerData.displayName || "Anonim", // Numele tasker-ului
          profile_link: `https://aidly.ro/profile/${service.uid}`, // Link-ul profilului proprietarului
        };

        emailjs
          .send(
            "service_exj3u5d", // ID-ul serviciului EmailJS
            "template_mailtoowner", // ID-ul template-ului EmailJS
            emailTemplateParams,
            "8P20l58Dg2sQRxHiK" // User ID-ul EmailJS
          )
          .then(
            () => {
              toast.success("Proprietarul a fost notificat prin email.");
            },
            (error) => {
              console.error("Eroare la trimiterea emailului:", error);
              toast.error("Eroare la trimiterea notificării prin email.");
            }
          );

        toast.success(`Oferta pentru "${service.title}" a fost acceptată!`);
        onClose();
      } catch (error) {
        console.error("Error accepting offer:", error);
        toast.error("Eroare la acceptarea ofertei.");
      }
    }
  };

  const handleOwnerClick = () => {
    navigate(`/user/${service.uid}`);
  };

  const reportAdLink = `mailto:contact@aidly.ro?subject=Anunt Raportat (ID: ${
    service.id
  })&body=${encodeURIComponent(
    `Bună ziua,

Vă raportez anunțul cu titlul: "${service.title}" și ID-ul: "${service.id}" pentru încălcarea următoarei reguli:

(Descrieți motivul aici).`
  )}`;

  // =========================
  //  Share on Social Media
  // =========================
  const shareOnWhatsApp = `https://wa.me/?text=${encodeURIComponent(
    `Vezi acest anunț:
Titlu: ${service.title}
Descriere: ${service.description || "Fără descriere"}
Preț: ${service.price} RON
Locație: ${service.location}
Accesează anunțul: https://aidly.ro/ad?id=${service.id}`
  )}`;

  const shareOnFacebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `https://aidly.ro/ad?id=${service.id}`
  )}`;

  return (
    <div className="overlay-backdrop" onClick={onClose}>
      <div className="overlay-window" onClick={(e) => e.stopPropagation()}>
        {/* Header */}
        <div className="modal-header">
          <h2 className="modal-title">{service.title}</h2>
        </div>

        {/* Content */}
        <div className="modal-content-ad">
          {/* Left Section */}{" "}
          <div className="modal-info-ad">
            <p>
              <strong>{t("modal.description")}:</strong>{" "}
              {service.description || t("modal.no_description")}
            </p>

            <p>
              <strong>{t("modal.price")}:</strong> {service.price} RON
            </p>
            <p>
              <strong>{t("modal.location")}:</strong> {service.location}
            </p>

            <p className="modal-category">
              <strong>Categorie:</strong> {service.category || "Nespecificată"}
            </p>
            <p className="modal-owner">
              <strong>Proprietar:</strong>{" "}
              <span
                className="owner-link"
                onClick={handleOwnerClick}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}>
                {ownerName}
              </span>
            </p>
            <div className="share-buttons">
              <a
                href={shareOnWhatsApp}
                className="action-btn share whatsapp"
                target="_blank"
                rel="noopener noreferrer">
                Distribuie pe WhatsApp
              </a>
              <a
                href={shareOnFacebook}
                className="action-btn share facebook"
                target="_blank"
                rel="noopener noreferrer">
                Distribuie pe Facebook
              </a>
            </div>
          </div>
          {/* Right Section: Map */}
          <div className="modal-map">
            <iframe
              title="Service Location"
              src={googleMapLink}
              allowFullScreen
              loading="lazy"></iframe>
          </div>
        </div>

        {/* Footer */}
        <div className="modal-footer-ad">
          {/* Left-Aligned Report Button */}
          <div style={{ flex: "1", textAlign: "left" }}>
            <a
              href={reportAdLink}
              className="action-btn report"
              style={{
                backgroundColor: "#e74c3c",
                color: "#fff",
                textDecoration: "none",
              }}>
              Raportează Anunț
            </a>
          </div>

          {/* Right-Aligned Close and Accept Buttons */}
          <div style={{ flex: "1", textAlign: "right" }}>
            <button className="action-btn close" onClick={onClose}>
              {t("modal.close")}
            </button>
            <button className="action-btn accept" onClick={handleAccept}>
              {t("modal.accept")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
