import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  updateDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../data/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../styling/chatbot.css";
import { CSSTransition } from "react-transition-group";
import IconChat from "../assets/icon_chat.webp";
import { toast } from "react-toastify";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [showChatbot, setShowChatbot] = useState(false);
  const [showFAQ, setShowFAQ] = useState(true);
  const [conversationId, setConversationId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [groupedMessages, setGroupedMessages] = useState({});
  const [visibleDays, setVisibleDays] = useState([]);

  const auth = getAuth();

  const faqs = [
    {
      question: "Cum pot să creez un cont?",
      answer:
        "Pentru a crea un cont, accesați pagina de înregistrare și completați formularul.",
    },
    {
      question: "Cum pot să postez un anunț?",
      answer:
        'După ce v-ați autentificat, accesați secțiunea "Postează anunț" din meniul principal.',
    },
    {
      question: "Cum pot să contactez un vânzător?",
      answer:
        "Puteți contacta vânzătorul folosind informațiile de contact disponibile în anunț.",
    },
  ];

  // Monitor user authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName); // Salvați displayName în starea componentului
        initializeConversation(user.uid);
      } else {
        setUserId(null);
        setDisplayName(null);
        setConversationId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const grouped = messages.reduce((acc, message) => {
      const date = new Date(message.timestamp.seconds * 1000)
        .toISOString()
        .split("T")[0];
      if (!acc[date]) acc[date] = [];
      acc[date].push(message);
      return acc;
    }, {});

    setGroupedMessages(grouped);

    // Afișează doar ziua curentă inițial
    const currentDay = new Date().toISOString().split("T")[0];
    setVisibleDays([currentDay]);
  }, [messages]);

  const showMoreMessages = () => {
    const sortedDays = Object.keys(groupedMessages).sort(
      (a, b) => new Date(b) - new Date(a)
    );
    const nextDay = sortedDays.find((day) => !visibleDays.includes(day));

    if (nextDay) {
      setVisibleDays((prev) => [...prev, nextDay]);
    }
  };

  // Initialize or fetch conversation for the user
  const initializeConversation = async (userId) => {
    try {
      const conversationsRef = collection(firestore, "conversations");
      const q = query(conversationsRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Set the existing conversation ID
        setConversationId(querySnapshot.docs[0].id);
      } else {
        setConversationId(null); // Nu creează conversație nouă
      }
    } catch (error) {
      console.error("Error initializing conversation:", error);
    }
  };

  // Handle sending a message
  const handleSendMessage = async () => {
    if (userMessage.trim() === "") return;

    if (!userId) {
      toast.error("Trebuie să fii autentificat pentru a trimite mesaje.");
      return;
    }

    try {
      let currentConversationId = conversationId;

      // Create a new conversation if none exists
      if (!currentConversationId) {
        const conversationsRef = collection(firestore, "conversations");
        const newConversation = await addDoc(conversationsRef, {
          userId,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
        currentConversationId = newConversation.id;
        setConversationId(newConversation.id);
      }

      // Send the message
      const messagesRef = collection(
        firestore,
        `conversations/${currentConversationId}/messages`
      );

      await addDoc(messagesRef, {
        senderId: userId,
        senderName: displayName, // Adăugați displayName aici
        text: userMessage,
        timestamp: new Date(),
      });

      const conversationRef = doc(
        firestore,
        "conversations",
        currentConversationId
      );
      await updateDoc(conversationRef, {
        updatedAt: new Date(),
      });

      setUserMessage("");
      setShowFAQ(false);
    } catch (error) {
      toast.error("Eroare la trimiterea mesajului.");
      console.error("Eroare la trimiterea mesajului:", error);
    }
  };

  // Monitor user authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName); // Salvați displayName în starea componentului
        initializeConversation(user.uid); // Doar verifică dacă există conversația
      } else {
        setUserId(null);
        setDisplayName(null);
        setConversationId(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Listen to messages in real-time
  useEffect(() => {
    if (!conversationId) return;

    const messagesRef = collection(
      firestore,
      `conversations/${conversationId}/messages`
    );
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    // Real-time listener for messages
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    });

    // Cleanup the listener when component unmounts or conversationId changes
    return () => unsubscribe();
  }, [conversationId]);

  // Handle FAQ selection
  const handleFAQClick = async (faq) => {
    if (!userId || !conversationId) return;

    try {
      const messagesRef = collection(
        firestore,
        `conversations/${conversationId}/messages`
      );

      await addDoc(messagesRef, {
        sender: "user",
        text: faq.question,
        timestamp: new Date(),
      });

      await addDoc(messagesRef, {
        sender: "bot",
        text: faq.answer,
        timestamp: new Date(),
      });

      const conversationRef = doc(firestore, "conversations", conversationId);
      await updateDoc(conversationRef, {
        updatedAt: new Date(),
      });

      setShowFAQ(false);
    } catch (error) {
      console.error("Error handling FAQ click:", error);
    }
  };

  const handleBackToHomepage = () => {
    setShowFAQ(true);
  };

  return (
    <div className="chatbot-wrapper">
      {!showChatbot && (
        <div className="chatbot-icon" onClick={() => setShowChatbot(true)}>
          <img src={IconChat} alt="Chatbot" />
          <span className="tooltip-text">Ai nevoie de ajutor?</span>
        </div>
      )}

      <CSSTransition
        in={showChatbot}
        timeout={300}
        classNames="chatbot-transition"
        unmountOnExit>
        <div className="chatbot-container">
          <div className="chatbot-header">
            Aidly, ajutorul tau!
            <button
              className="chatbot-close-button"
              onClick={() => setShowChatbot(false)}>
              ✕
            </button>
          </div>
          <div className="chatbot-messages">
            {Object.keys(groupedMessages)
              .sort((a, b) => new Date(b) - new Date(a))
              .map((day) => {
                if (!visibleDays.includes(day)) return null;

                return (
                  <div key={day} className="chatbot-day">
                    <div className="chatbot-day-header">
                      {new Date(day).toLocaleDateString("ro-RO", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </div>
                    {groupedMessages[day].map((msg) => (
                      <div
                        key={msg.id}
                        className={`chatbot-message ${
                          msg.senderId === "user" ? "sent" : "received"
                        }`}>
                        <div className="message-text">{msg.text}</div>
                        <div className="message-timestamp">
                          {new Date(
                            msg.timestamp.seconds * 1000
                          ).toLocaleTimeString("ro-RO")}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}

            {Object.keys(groupedMessages).some(
              (day) => !visibleDays.includes(day)
            ) && (
              <button onClick={showMoreMessages} className="load-more-button">
                Arată mai multe
              </button>
            )}
          </div>

          <div className="chatbot-input">
            <input
              type="text"
              placeholder="Scrie mesajul tău..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            />
            <button onClick={handleSendMessage}>Trimite</button>
          </div>
          {!showFAQ && (
            <button className="back-button" onClick={() => setShowFAQ(true)}>
              ◀ Înapoi la FAQ
            </button>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Chatbot;
